import { useAuth, useUser } from '@clerk/clerk-react';
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle
} from "@repo/ui/components/ui/card";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Pencil, Plus, Trash2 } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { Spinner } from "../organisms/";
import { fetcher, serializeError } from '../services/api';

interface WorkspacesListProps {}

const WorkspacesList: React.FC<WorkspacesListProps> = () => {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();

  const { data, error, status } = useQuery({
    queryKey: ["get", "workspaces"],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/workspaces", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  const workspaceDeletion = useMutation({
    mutationFn: async (workspaceId: number) => {
      const { data, error, response } = await fetcher.DELETE("/workspaces/{workspace_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            workspace_id: workspaceId,
          }
        },
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["get", "workspaces"] });
      toast.success("Workspace deleted!",
        { description: data.data.name })
    },
    onError: (error) => {
      toast.error("Cannot delete workspace",
        { description: `${error}` })
    },
  })

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex justify-between">
        <h1 className="text-3xl font-semibold">Workspaces</h1>
        <Button
          size="sm"
          aria-label="Create workspace"
          asChild
        >
          <Link to="/workspaces/create">
            <div className="flex items-center gap-2">
              <Plus strokeWidth={2} className="size-5" />
              <span>New</span>
            </div>
          </Link>
        </Button>

      </div>
      <div className="flex flex-col w-full h-full gap-4">

        {
          data.data
            .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
            .map(w => (
              <Card>

                <CardHeader>
                  <div className="flex justify-between items-baseline">
                    <div className="flex gap-2">
                      <CardTitle>{w.name}</CardTitle>
                    </div>
                    <div className="flex gap-1">
                      <Button
                        variant="muted"
                        size="icon"
                        aria-label="Edit workspace"
                        asChild
                      >
                        <Link to={`/workspaces/edit/${w.id}`}>
                          <Pencil className="size-5" />
                        </Link>
                      </Button>
                      <Button
                        variant="muted"
                        size="icon"
                        aria-label="Delete workspace"
                        onClick={() => {
                          workspaceDeletion.mutate(w.id)
                        }}
                      >
                        <Trash2 className="size-5" />
                      </Button>
                    </div>
                  </div>
                  <CardDescription>{w.website}</CardDescription>
                </CardHeader>

                <CardContent>
                  {w.description}
                </CardContent>

              </Card>
            ))
        }
      </div>
    </div>
  )
}

WorkspacesList.displayName = "WorkspacesList";

export { WorkspacesList };
