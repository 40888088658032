import { useAuth } from '@clerk/clerk-react';
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
} from "@repo/ui/components/ui/card";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CircleCheck, LoaderCircle, Minus, Plus } from 'lucide-react';
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useShallow } from 'zustand/react/shallow';
import { useOnboardingUpdate } from "../lib/onboarding";

import { ContentTheme, WritingStatus } from "../organisms/";
import { fetcher, serializeError, useSocket } from '../services/api';
import { components } from '../services/api/openapi';
import { useOnboardingStore, usePrefsStore } from '../store';

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/components/ui/form";

interface CounterProps {
  initialValue: number
  onChange: (counter: number) => void
}

const Counter = ({
  initialValue,
  onChange
}: CounterProps) => {
  const [count, setCount] = useState(initialValue)
  const MIN_VALUE = 1
  const MAX_VALUE = 7

  const increment = () => {
    if (count < MAX_VALUE) {
      setCount(count + 1)
      onChange(count + 1)
    }
  }

  const decrement = () => {
    if (count > MIN_VALUE) {
      setCount(count - 1)
      onChange(count - 1)
    }
  }

  return (
    <div className="inline-flex items-center border rounded-md mx-1 bg-white">
      <Button
        variant="ghost"
        size="sm"
        className="h-8 w-8 p-0 rounded-l-md"
        onClick={decrement}
        disabled={count <= MIN_VALUE}
        aria-label="Decrease value"
      >
        <Minus className="h-3 w-3" />
      </Button>

      <span className="mx-0 min-w-6 text-center font-medium">{count}</span>

      <Button
        variant="ghost"
        size="sm"
        className="h-8 w-8 p-0 rounded-r-md"
        onClick={increment}
        disabled={count >= MAX_VALUE}
        aria-label="Increase value"
      >
        <Plus className="h-3 w-3" />
      </Button>
    </div>
  )
}

const weeksCoverage = (pillarsCount: number, clustersCount: number, weeklyFrequency: number): number => {
  return (pillarsCount + clustersCount) / weeklyFrequency
}

const durationCopy = (weeks: number): string => {
  const months = Math.floor(weeks / 4)
  if (months >= 12) {
    const years = Math.floor(months / 12)
    const remainingMonths = months - (12 * years)
    if (years < 3 && remainingMonths > 0) {
      const yearsCopy = `${years} year${years > 1 ? "s" : ""}`;
      const monthsCopy = `${remainingMonths} month${remainingMonths > 1 ? "s" : ""}`;
      return `${yearsCopy} and ${monthsCopy}`;
    } else {
      return `${years} year${years > 1 ? "s" : ""}`
    }
  } else {
    return `${months} month${months > 1 ? "s" : ""}`
  }
}

interface OnboardingCreateContentHubDoneProps {
  contentTopics: components["schemas"]["FullContentTheme"][]
  onTopicSelected: (topicId: number) => void
}

const OnboardingCreateContentHubDone = ({
  contentTopics,
  onTopicSelected,
}: OnboardingCreateContentHubDoneProps) => {
  let pillarsCount = 0;
  let clustersCount = 0;
  const themes = {
    // 1: {
    //   "id": ...,
    //   "name": ...,
    //   "description": ...,
    //   "childrenTopics": ...
    // }
  }
  contentTopics
    .forEach(topic => {
      if (!themes[topic.content_theme_id]) {
        themes[topic.content_theme_id] = {
          id: topic.content_theme_id,
          name: topic.content_theme?.name,
          description: topic.content_theme?.description,
          childrenTopics: contentTopics
            .filter(innerTopic => innerTopic.content_role === "pillar")
            .filter(innerTopic => innerTopic.content_theme_id === topic.content_theme_id)
            .sort((a, b) => a.id - b.id)
            .map(innerTopic => {
              pillarsCount += 1
              return {
                topicId: innerTopic.id,
                blogPostId: innerTopic.blog_post?.id,
                contentRole: innerTopic.content_role,
                isBookmarked: innerTopic.is_bookmarked,
                name: innerTopic.name,
                focus: innerTopic.focus,
                targetAudience: innerTopic.target_audience,
                reason: innerTopic.reason,
                status: innerTopic.blog_post ? (
                  innerTopic.blog_post.status === "done" ?
                    WritingStatus.DONE : WritingStatus.PENDING
                ) : WritingStatus.IDLE,
                pendingStatusPhase: innerTopic.blog_post?.status,
                childrenTopics: contentTopics
                  .filter(innerInnerTopic => innerInnerTopic.content_role === "cluster")
                  .filter(innerInnerTopic => innerInnerTopic.parent_content_topic_id === innerTopic.id)
                  .sort((a, b) => a.id - b.id)
                  .map(_ => {
                    clustersCount += 1
                    return null;
                  })
                  .filter(v => !!v)
              }
            })
        }
      }
    })

  const [weeklyFrequency, setWeeklyFrequency] = useState(4)
  const [totalWeeksCovered, setTotalWeeksCovered] = useState(
    weeksCoverage(pillarsCount, clustersCount, weeklyFrequency)
  )

  const onCounterChange = useCallback((newWeeklyFrequency: number) => {
    setWeeklyFrequency(newWeeklyFrequency)
    setTotalWeeksCovered(
      weeksCoverage(pillarsCount, clustersCount, newWeeklyFrequency)
    )
  }, [setWeeklyFrequency, pillarsCount, clustersCount])

  return (
    <div className="flex flex-col gap-16 text-center mt-24 items-center">
      <div className="flex flex-col gap-8 text-center">
        <div className="flex flex-col gap-4 items-center text-center max-w-6xl">
          <h1 className="text-6xl font-semibold">
            Whoa! {pillarsCount + clustersCount} content ideas, just like that 💥
          </h1>
          <span className="text-3xl font-regular text-muted-foreground">
            We’ve organized your strategy into {pillarsCount} pillars and {clustersCount} clusters — let’s turn them into real posts — in <span className="font-semibold italic">one click</span>.
          </span>
        </div>

        <div className="text-xl">
          At <Counter initialValue={weeklyFrequency} onChange={onCounterChange} /> posts a week, that's over <span className="font-semibold underline">{durationCopy(totalWeeksCovered)}</span> of content! 🤯
        </div>
      </div>

      <div className="flex flex-col gap-12 text-center">
        <span className="text-2xl font-semibold">Pick your first pillar post ✍️</span>
        <div className="flex flex-col gap-1 items-center">
          {
            Object.values(themes)
              .sort((a, b) => a.id - b.id)
              .map(theme => (
                <ContentTheme
                  key={theme.id}
                  name={theme.name}
                  description={theme.description}
                  topics={theme.childrenTopics}
                  enabledControls={[]}
                  onTopicClick={onTopicSelected}
                />
              ))
          }
        </div>
      </div>
    </div>
  )
}

interface OnboardingCreateContentHubProgressProps {
  steps: Step[]
}

const OnboardingCreateContentHubProgress = ({
  steps
}: OnboardingCreateContentHubProgressProps) => {

  return (
    <div className="flex flex-col gap-24 items-center">
      <div className="flex flex-col gap-4 items-center text-center max-w-6xl">
        <h1 className="text-6xl font-semibold">
          Hang tight!<br />We’re building your Content Hub 🚀
        </h1>
        <span className="text-3xl font-regular text-muted-foreground">
          Your blog strategy is already in motion—a system organized into Themes, Pillars, and Clusters designed to rank, engage, and scale with you.
        </span>
      </div>
      <Card className="bg-transparent border-0 shadow-none max-w-3xl">
        <CardContent className="p-0 space-y-2">
          {
            steps.map(s => (
              <div key={`step-${s.id}`} className="flex w-full justify-between items-center mr-4 gap-2">
                <span className="w-full text-left text-xl">{s.message}</span>
                {s.isDone ? (
                  <div>
                    <CircleCheck className="text-success-foreground" size="24" />
                  </div>
                ) : (
                  <div className="animate-spin">
                    <LoaderCircle size="22" />
                  </div>
                )}
              </div>
            ))
          }
        </CardContent>
      </Card>
    </div>
  )
}

interface Step {
  id: string;
  message: string;
  isDone: boolean;
}


interface StepStartedPayload {
  id: string;
  message: string;
  timestamp: number;
}
interface StepFinishedPayload {
  id: string;
  timestamp: number;
}

interface WorkflowStartedPayload {}
interface WorkflowFinishedPayload {
  message: string;
  workspace_id: number
}

interface ContentHubGenerationErrorPayload {
  message: string;
}

const OnboardingCreateContentHub = () => {
  const { getToken } = useAuth();
  const socket = useSocket(getToken);
  const queryClient = useQueryClient();

  const [isTriggered, setIsTriggered] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [isFinishedGenerating, setIsFinishedGenerating] = useState(false);
  const [steps, setSteps] = useState<Step[]>([]);

  const navigate = useNavigate();
  const currentWorkspace = usePrefsStore((state) => state.currentWorkspace)
  const [setSelectedTopicId] = useOnboardingStore(
    useShallow((state) => [state.setSelectedTopicId]),
  )
  const onboardingNextStepUpdater = useOnboardingUpdate()

  useEffect(() => {
    if (isFinished) {
      if (onboardingNextStepUpdater) {
        onboardingNextStepUpdater("generate-blog-post")
      }
      return navigate("/onboarding/generate-blog-post");
    }
  }, [isFinished, onboardingNextStepUpdater])

  useEffect(() => {
    if (socket) {
      socket.on("content-themes:create:error", (data: ContentHubGenerationErrorPayload) => {
        toast.error("Cannot generate content hub",
          { description: `${data.message}` })
      })

      socket.on("workflow:started", (_: WorkflowStartedPayload) => {
        setIsTriggered(true)
      })

      socket.on("workflow:finished", (data: WorkflowFinishedPayload) => {
        queryClient.invalidateQueries({
          queryKey: ["list", "content-themes", currentWorkspace?.id]
        });

        window.setTimeout(() => {
          setIsFinishedGenerating(true)
        }, [1000])
      })

      socket.on("step:started", (data: StepStartedPayload) => {
        setSteps(prevSteps => [
          ...prevSteps,
          { id: data.id, message: data.message, isDone: false }
        ])
      })

      socket.on("step:finished", (data: StepFinishedPayload) => {
        setSteps(prevSteps =>
          prevSteps.map(step =>
            step.id === data.id ? { ...step, isDone: true } : step
          )
        );
      })
    }
  }, [socket, currentWorkspace])

  useEffect(() => {
    if (socket && !isTriggered && currentWorkspace?.id) {

      socket.emit("content-themes:create", {
        workspace_id: currentWorkspace?.id
      })
    }
  }, [socket, isTriggered, currentWorkspace?.id])

  const { data, status } = useQuery({
    queryKey: ["list", "content-topics", currentWorkspace?.id],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/content-topics", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          query: {
            expand: [
              "content_theme",
              "blog_post"
              // TODO: Initially this was querying /content-themes but
              // there are some issues in fetching .blog_post in the same query
              //
              // "content_topics.blog_post"
              //
              // In general with nested relationships there are some issues
              // in how the "Full" schema is built.
              // So I used /content-topics to avoid needing nested "expand".
            ],
            workspace_id: currentWorkspace?.id,
            page_size: 500
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isFinishedGenerating
  })

  const onTopicSelected = useCallback((topicId: number) => {
    setSelectedTopicId(topicId)
    setIsFinished(true)
  }, [])

  return (
    <>
      {
        status === "success" ? (
          <OnboardingCreateContentHubDone
            pillarsCount={35}
            clustersCount={210}
            contentTopics={data?.data || []}
            onTopicSelected={onTopicSelected}
          />
        ) : (
          <OnboardingCreateContentHubProgress steps={steps} />
        )
      }
    </>
  )
}

export { OnboardingCreateContentHub };
