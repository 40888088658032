import { useAuth } from '@clerk/clerk-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { fetcher, serializeError } from '../../services/api';
import { components } from '../../services/api/openapi';
import { usePrefsStore } from '../../store';

interface useBlogPostAsyncGenerationInput {
  topicId: number;
  name: string;
  focus: string;
  targetAudience: string;
}

type useBlogPostAsyncGenerationOutput = () => void;

const useBlogPostAsyncGeneration = ({
  topicId,
  name,
  focus,
  targetAudience
}: useBlogPostAsyncGenerationInput): useBlogPostAsyncGenerationOutput => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const currentWorkspace = usePrefsStore((state) => state.currentWorkspace)

  const blogPostAsyncGeneration = useMutation({
    mutationFn: async (blogPostAsyncPayload: components["schemas"]["GenerateBlogPostAsyncRequest"]) => {
      const { data, error, response } = await fetcher.POST("/blog-posts/generate-async", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        body: blogPostAsyncPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get", "blogPosts", currentWorkspace?.id] });
      queryClient.invalidateQueries({ queryKey: ["list", "content-topics", currentWorkspace?.id] });
      queryClient.invalidateQueries({ queryKey: ["list", "features-usages"] });

      toast.success("Blog post queued!")
    },
    onError: (error) => {
      toast.error("Cannot generate blog post",
        { description: `${error}` })
    },
  })

  const onGenerateBlogPostAsync = useCallback(() => {
    const topic = `${name}. Focus: ${focus}`
    const tone = ""
    blogPostAsyncGeneration.mutate({
      topic,
      tone,
      target_audience: targetAudience,
      workspace_id: currentWorkspace?.id as number,
      content_topic_id: topicId
    })
  }, [topicId, name, focus, targetAudience, currentWorkspace, blogPostAsyncGeneration])

  return onGenerateBlogPostAsync;
}


interface useOnDeleteConentTopicInput {
  topicId: number
}

type useOnDeleteConentTopicOutput = () => void;

const useOnDeleteConentTopic = ({
  topicId
}: useOnDeleteConentTopicInput): useOnDeleteConentTopicOutput => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const currentWorkspace = usePrefsStore((state) => state.currentWorkspace)

  const contentTopicDeletion = useMutation({
    mutationFn: async (topicId: number) => {
      const { data, error, response } = await fetcher.DELETE("/content-topics/{content_topic_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            content_topic_id: topicId
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["list", "content-topics", currentWorkspace?.id] });

      toast.success("Topic deleted!")
    },
    onError: (error) => {
      toast.error("Cannot delete topic",
        { description: `${error}` })
    },
  })

  const onDeleteContentTopic = useCallback(() => {
    contentTopicDeletion.mutate(topicId)
  }, [topicId, contentTopicDeletion])

  return onDeleteContentTopic;
}


interface useOnBookmarkConentTopicInput {
  topicId: number;
  isBookmarked: boolean;
}

type useOnBookmarkConentTopicOutput = () => void;

const useOnBookmarkConentTopic = ({
  topicId, isBookmarked
}: useOnBookmarkConentTopicInput): useOnBookmarkConentTopicOutput => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const currentWorkspace = usePrefsStore((state) => state.currentWorkspace)

  const contentTopicBookmark = useMutation({
    mutationFn: async ({
      topicId,
      contentTopicPayload
    }: {
      topicId: number,
      contentTopicPayload: components["schemas"]["UpdateContentTopicRequest"]
    }) => {
      const { data, error, response } = await fetcher.PATCH("/content-topics/{content_topic_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            content_topic_id: topicId
          }
        },
        body: contentTopicPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["list", "content-topics", currentWorkspace?.id] });
      if (isBookmarked) {
        toast.success("Bookmark removed from topic!")
      } else {
        toast.success("Topic bookmarked!")
      }

    },
    onError: (error) => {
      toast.error("Cannot bookmark topic",
        { description: `${error}` })
    },
  })

  const onDeleteContentTopic = useCallback(() => {
    contentTopicBookmark.mutate(
      { topicId, contentTopicPayload: { is_bookmarked: !isBookmarked } }
    )
  }, [topicId, contentTopicBookmark])

  return onDeleteContentTopic;
}


interface useOnGoToConentTopicBlogPostInput {
  blogPostId: number | null;
}

type useOnGoToConentTopicBlogPostOutput = () => void;

const useOnGoToConentTopicBlogPost = ({
  blogPostId
}: useOnGoToConentTopicBlogPostInput): useOnGoToConentTopicBlogPostOutput => {
  const navigate = useNavigate();
  return () => {
    if (blogPostId) {
      return navigate(`/content/blog-posts/edit/${blogPostId}`);
    }
  }
}


export {
  useBlogPostAsyncGeneration,
  useOnBookmarkConentTopic,
  useOnDeleteConentTopic,
  useOnGoToConentTopicBlogPost
};
