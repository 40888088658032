import { ContentTopicProps } from "../contentTopic";
import { ContentThemeUI } from "./ContentTheme.ui";

export interface ContentThemeProps {
  name: string;
  description: string;
  topics: ContentTopicProps[];
  enabledControls?: ("generate" | "delete" | "bookmark")[];
  onTopicClick?: ((topicId: number) => void) | null;
}

const ContentTheme = ({
  name,
  description,
  topics,
  enabledControls,
  onTopicClick,
}: ContentThemeProps) => {
  return (
    <ContentThemeUI
      name={name}
      description={description}
      topics={topics}
      enabledControls={enabledControls || ["generate", "delete", "bookmark"]}
      onTopicClick={onTopicClick}
    />
  );
};

ContentTheme.displayName = "ContentTheme";

export { ContentTheme };
