import { useAuth, useUser } from '@clerk/clerk-react';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@repo/ui/components/ui/avatar";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@repo/ui/components/ui/tabs";
import { Textarea } from "@repo/ui/components/ui/textarea";
import { useMutation, useQuery } from '@tanstack/react-query';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from "react-router-dom";
import rehypeHighlight from 'rehype-highlight';
import remarkGfm from 'remark-gfm';
import { toast } from "sonner";
import { Spinner } from "../organisms/";
import { fetcher, serializeError } from '../services/api';
import { components } from '../services/api/openapi';

const generateSlug = (title: string): string => {
  return title
    .toLowerCase() // Convert to lowercase
    .trim() // Remove leading/trailing spaces
    .replace(/[\s_]+/g, '-') // Replace spaces and underscores with dashes
    .replace(/[^\w-]/g, '') // Remove non-word characters except dashes
    .replace(/--+/g, '-'); // Replace multiple dashes with a single dash
}

import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  ButtonWithTooltip,
  codeBlockPlugin,
  CodeMirrorEditor,
  codeMirrorPlugin,
  CreateLink,
  headingsPlugin,
  imagePlugin,
  InsertCodeBlock,
  InsertImage,
  InsertTable,
  InsertThematicBreak,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  MDXEditorMethods,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  UndoRedo,
  type ToolbarContentProps
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { Button } from '@repo/ui-core';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@repo/ui/components/ui/accordion";
import { Badge } from "@repo/ui/components/ui/badge";
import { Card, CardContent, CardHeader, } from "@repo/ui/components/ui/card";
import { Label } from "@repo/ui/components/ui/label";
import { ScrollArea } from "@repo/ui/components/ui/scroll-area";
import { Separator } from "@repo/ui/components/ui/separator";
import { Copy, ExternalLink } from 'lucide-react';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import invariant from 'tiny-invariant';

interface CopyContentButtonProps {
  mdContent: string;
}

const copyTextToClipboard = async (text: string) => {
  if (navigator?.clipboard?.writeText) {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Content copied to clipboard!");
    } catch (err) {
      console.error('Failed to copy text via Clipboard API:', err);
      fallbackCopyText(text);
    }
  } else {
    fallbackCopyText(text);
  }
};

const fallbackCopyText = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed';
  textArea.style.top = '-9999px';
  textArea.style.left = '-9999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    if (successful) {
      toast.success("Content copied to clipboard!");
    } else {
      console.error('Fallback: Copy command was unsuccessful.');
      toast.error("Failed to copy content");
    }
  } catch (err) {
    console.error('Fallback: Unable to copy', err);
    toast.error("Failed to copy content");
  }
  document.body.removeChild(textArea);
};

const CopyContentButton: React.FC<CopyContentButtonProps> = ({
  mdContent
}) => {
  return (
    <ButtonWithTooltip
      title="Copy Content"
      onClick={() => copyTextToClipboard(mdContent)}
      disabled={false}
    >
      <div className="flex gap-1 items-center bg-primary rounded-md py-1 px-2 cursor-pointer">
        <Copy className="h-5 w-5" />
        <span className="text-sm">Copy raw</span>
      </div>
    </ButtonWithTooltip>
  );
};

interface CanvasProps {
  blogPost: components["schemas"]["FullBlogPost"],
  readOnly: boolean;
}

const Canvas: React.FC<CanvasProps> = ({
  blogPost,
  readOnly
}) => {
  const blogPostId = blogPost.id
  const title = blogPost.title
  const mdBody = blogPost.md_content
  const heroImageUrl = blogPost.hero_image_url

  const { getToken } = useAuth();
  const debounceTimeout = useRef(null);

  const mdxEditorRef = useRef<MDXEditorMethods>(null)
  const [mdContent, setMdContent] = useState("");

  useEffect(() => {
    const mdTitle = `# ${title}`;
    const mdHeroImage = heroImageUrl ? `![Hero image](${heroImageUrl})` : ""
    const mdContent = `${mdTitle}\n${mdHeroImage}\n${mdBody}`

    setMdContent(mdContent)
    mdxEditorRef.current?.setMarkdown(mdContent)
  }, [blogPost.md_content])

  const blogPostUpdate = useMutation({
    mutationFn: async (blogPostPayload: components["schemas"]["UpdateBlogPostRequest"]) => {
      const { data, error, response } = await fetcher.PATCH("/blog-posts/{blog_post_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            blog_post_id: blogPostId
          }
        },
        body: blogPostPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      const blogPost = data.data;
      const title = blogPost.title;
      const mdBody = blogPost.md_content;
      const heroImageUrl = blogPost.hero_image_url;

      const mdTitle = `# ${title}`;
      const mdHeroImage = heroImageUrl ? `![Hero image](${heroImageUrl})` : ""
      const mdContent = `${mdTitle}\n${mdHeroImage}\n${mdBody}`

      setMdContent(mdContent)
      toast.success("Blog post updated!",
        { description: title })
    },
    onError: (error) => {
      toast.error("Cannot update blog post",
        { description: `${error}` })
    },
  })

  const onChange = useCallback((newMdContent: string) => {
    clearTimeout(debounceTimeout.current);

    const [titleLine, ...bodyLines] = newMdContent.split('\n');
    const title = titleLine.startsWith('#') ? titleLine.replace(/^#\s*/, '') : null;
    const mdBodyContent = bodyLines
      .filter(l => !l.startsWith("![Hero image]"))
      .join('\n');

    const blogPostPayload = {
      title,
      status: blogPost.status,
      md_content: mdBodyContent,
    }

    if (mdContent !== newMdContent) {
      debounceTimeout.current = setTimeout(() => {
        blogPostUpdate.mutate(blogPostPayload);
      }, 1000);
    }
  }, [mdContent, blogPostUpdate])

  return (
    <MDXEditor
      ref={mdxEditorRef}
      markdown={mdContent}
      className="max-w-none prose"
      readOnly={readOnly}
      onChange={onChange}
      plugins={[
        codeBlockPlugin({
          defaultCodeBlockLanguage: '',
          codeBlockEditorDescriptors: [
            {
              priority: -10,
              match: (_) => true,
              Editor: CodeMirrorEditor
            }
          ]
        }),
        codeMirrorPlugin({
          codeBlockLanguages: {
            "python": "Python",
            "javascript": "JavaScript",
            "typescript": "TypeScript",
            "java": "Java",
            "c": "C",
            "cpp": "C++",
            "csharp": "C#",
            "ruby": "Ruby",
            "go": "Go",
            "swift": "Swift",
            "html": "HTML",
            "css": "CSS",
            "shell": "Shell",
            "php": "PHP",
            "json": "JSON",
            "yaml": "YAML",
            "xml": "XML",
            "sql": "SQL",
            "scala": "Scala",
            "kotlin": "Kotlin",
            "rust": "Rust",
            "elixir": "Elixir",
            "": "Unspecified"
          },
          autoLoadLanguageSupport: true
        }),
        headingsPlugin(),
        listsPlugin(),
        quotePlugin(),
        thematicBreakPlugin(),
        linkPlugin(),
        linkDialogPlugin(),
        imagePlugin(),
        tablePlugin(),
        toolbarPlugin({

          toolbarClassName: 'bg-background border',
          toolbarContents: () => (
            <div className="flex justify-between w-full">
              <div className="flex">
                <BlockTypeSelect />
                <BoldItalicUnderlineToggles />
                <CreateLink />
                <InsertImage />
                <InsertTable />
                <InsertCodeBlock />
                <InsertThematicBreak />
                <ListsToggle />
                <UndoRedo />
              </div>
              <CopyContentButton
                ButtonComponent={ButtonWithTooltip}
                buttonCopy="Copy raw"
                mdContent={mdContent} />
            </div>
          )
        }),
        markdownShortcutPlugin(),
      ]}
    />
  )
}

interface SideContainerProps {
  blogPost: components["schemas"]["FullBlogPost"],
  authorData: components["schemas"]["GetBlogPostResponse"]["extra"]
}

const SideContainer: React.FC<SideContainerProps> = ({
  blogPost,
  authorData
}) => {
  const createdAt = new Date(blogPost.created_at);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedCreatedAt = createdAt.toLocaleDateString("en-US", options);
  const avatarFallback = (
    (authorData?.first_name ? authorData?.first_name[0] || "" : "") +
    (authorData?.last_name ? authorData?.last_name[0] || "" : "")
  )
  return (
    <Tabs defaultValue="references" className="w-full">
      <TabsList className="grid w-full grid-cols-3">
        <TabsTrigger value="overview">Overview</TabsTrigger>
        <TabsTrigger value="references">References</TabsTrigger>
        <TabsTrigger value="sources">Sources</TabsTrigger>
      </TabsList>
      <TabsContent value="overview">
        <Card className="w-full h-full overflow-hidden flex flex-col">
          <CardHeader className="flex flex-col w-full gap-4 items-center font-semibold text-lg">
            Overview
          </CardHeader>
          <Separator />
          <CardContent className="flex flex-col gap-4 py-6">
            <div className="flex items-center gap-2 text-sm">
              <span className="font-medium shrink-0">Author</span>
              <Avatar>
                <AvatarImage src={authorData?.image_url} alt="avatar" />
                {avatarFallback && <AvatarFallback>{avatarFallback}</AvatarFallback>}
              </Avatar>
              <Badge variant="outline" className="capitalize">
                {authorData?.first_name} {authorData?.last_name}
              </Badge>
            </div>

            <div className="flex items-center gap-2 text-sm">
              <span className="font-medium shrink-0">Created at</span>
              <Badge variant="outline" className="capitalize">
                {formattedCreatedAt}
              </Badge>
            </div>

            <div className="flex items-center gap-2 text-sm">
              <span className="font-medium shrink-0">User journey stage</span>
              <Badge variant="outline" className="capitalize">
                {blogPost.user_journey_stage}
              </Badge>
            </div>

            <div className="flex items-center gap-2 text-sm">
              <span className="font-medium shrink-0">Search intent</span>
              <Badge variant="outline" className="capitalize">
                {blogPost.search_intent}
              </Badge>
            </div>

            <div className="flex flex-col gap-2 text-sm">
              <Label htmlFor="topic" className="font-medium">Topic</Label>
              <Textarea
                id="topic"
                className="resize-none h-fit focus-visible:outline-none"
                value={blogPost.topic}
                readOnly={true}
              />
            </div>

            <div className="flex flex-col gap-2 text-sm">
              <Label htmlFor="tone" className="font-medium">Tone</Label>
              <Textarea
                id="tone"
                className="resize-none h-fit focus-visible:outline-none"
                value={blogPost.tone}
                readOnly={true}
              />
            </div>

            <div className="flex flex-col gap-2 text-sm">
              <Label htmlFor="targetAudience" className="font-medium">Target audience</Label>
              <Textarea
                id="targetAudience"
                className="resize-none h-fit focus-visible:outline-none"
                value={blogPost.target_audience}
                readOnly={true}
              />
            </div>

          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="references">
        <Card className="h-full overflow-hidden flex flex-col">
          <CardHeader className="flex flex-col w-full gap-4 items-center font-semibold text-lg">
            References
          </CardHeader>
          <Separator />
          <ScrollArea className="flex flex-col h-full gap-2 py-4 overflow-y-auto">
            <CardContent>
              <div className="flex flex-col gap-6">
                <Accordion type="single" collapsible className="w-full">
                  {
                    blogPost.used_references?.map(ur => {
                      const canonicalReference = blogPost.references?.find(r => r.id == ur.canonical_reference_id)
                      return (
                        <AccordionItem key={`reference-${ur.id}`} value={ur.id} >
                          <AccordionTrigger className="text-sm font-normal no-underline hover:no-underline">
                            <div className="flex flex-col gap-6">
                              <div className="flex flex-col gap-1">
                                <span className="italic text-sm">"{ur.text}"</span>
                              </div>
                            </div>
                          </AccordionTrigger>
                          <AccordionContent>
                            <div className="flex flex-col gap-1">
                              <span className="font-semibold text-md">Sources</span>
                              {
                                canonicalReference?.extracted_references?.map(er => {
                                  const blogPostSource = blogPost.sources?.find(s => s.id == er.blost_post_source_id)
                                  return (
                                    <Card>
                                      <CardContent className="py-2 px-4">
                                        <div className="flex flex-col gap-1">
                                          <div className="flex flex-col gap-1">
                                            <span className="font-semibold text-sm">Title</span>
                                            <div className="flex gap-2">
                                              <span className="text-sm">{blogPostSource.title}</span>
                                              <a href={blogPostSource.url} target="_blank" className="italic text-muted-foreground">
                                                <ExternalLink className="size-4" />
                                              </a>
                                            </div>
                                          </div>
                                          <div className="flex flex-col gap-1">
                                            <span className="font-semibold text-sm">Excerpt</span>
                                            <span className="text-xs italic text-muted-foreground">"{er.snippet}"</span>
                                          </div>
                                        </div>
                                      </CardContent>
                                    </Card>
                                  )
                                })
                              }
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      )
                    })
                  }
                </Accordion>
              </div>
            </CardContent>
          </ScrollArea>
        </Card>
      </TabsContent>
      <TabsContent value="sources">
        <Card className="h-full overflow-hidden flex flex-col">
          <CardHeader className="flex flex-col w-full gap-4 items-center font-semibold text-lg">
            Sources
          </CardHeader>
          <Separator />
          <ScrollArea className="flex flex-col h-full gap-2 py-4 overflow-y-auto">
            <CardContent>
              <Accordion type="single" collapsible className="w-full">
                <span className="text-sm">Avg. Rank</span>
                {
                  blogPost.sources?.map(source => (
                    <AccordionItem key={`source-${source.id}`} value={source.id} >
                      <AccordionTrigger className="group no-underline hover:no-underline">
                        <div className="flex items-center gap-2">
                          <Badge variant="info" shape="circle" >
                            {source.serp["average_google_position"]}
                          </Badge>
                          <span className="text-xs group-hover:underline">{source.title}</span>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="w-full flex flex-col gap-4">
                          <div className="w-full flex flex-col gap-2">
                            <div className="w-full flex justify-between text-md">
                              <span className="font-semibold">Summary</span>
                              <a href={source.url} target="_blank" className="italic text-muted-foreground">
                                <div className="w-full flex gap-1 justify-end items-center">
                                  Go to source
                                  <ExternalLink className="size-4" />
                                </div>
                              </a>
                            </div>
                            <ReactMarkdown
                              className="max-w-none prose prose-sm"
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeHighlight]}
                            >
                              {source.summary}
                            </ReactMarkdown>
                          </div>
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  ))
                }
              </Accordion>
            </CardContent>
          </ScrollArea>
        </Card>
      </TabsContent>
    </Tabs>
  )
}

SideContainer.displayName = "SideContainer"


interface ContentBlogPostsEditProps {}

const ContentBlogPostsEdit: React.FC<ContentBlogPostsEditProps> = () => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();
  const navigate = useNavigate();
  const { blogPostId } = useParams();
  invariant(blogPostId, "Unexpected blogPostId")

  const { data, error, status } = useQuery({
    queryKey: ["get", "blogPosts", blogPostId],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/blog-posts/{blog_post_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            blog_post_id: parseInt(blogPostId)
          },
          query: {
            expand: [
              "author",
              "sources",
              "used_references",
              "references.extracted_references",
            ]
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  if (data?.data.status !== "done") {
    return navigate(`/content/blog-posts/library`)
  }

  const titleSlug = generateSlug(data?.data.title)

  return (
    <div className="flex flex-col w-full h-full justify-center items-center">
      <div className="flex gap-4 w-full h-full">
        <Card className="w-full h-full">
          <ScrollArea className="flex flex-col h-full pt-4 pb-4 overflow-y-auto">
            <CardContent className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center">
                  <span className="text-sm">Suggested path:</span>
                  <Badge
                    variant="outline"
                    className="w-fit text-xs bg-white flex gap-2 items-center hover:cursor-pointer"
                    onClick={() => copyTextToClipboard(titleSlug)}
                  >
                    {titleSlug}
                    <Copy className="h-4 w-4 shrink-0" />
                  </Badge>
                </div>

                {data.data.excerpt && (
                  <div className="flex gap-2 items-center">
                    <span className="text-sm">Excerpt:</span>
                    <Badge
                      variant="outline"
                      className="w-fit text-xs bg-white flex gap-2 items-center hover:cursor-pointer"
                      onClick={() => copyTextToClipboard(data.data.excerpt)}
                    >
                      {data.data.excerpt}
                      <Copy className="h-4 w-4 shrink-0" />
                    </Badge>
                  </div>
                )}
              </div>

              <Canvas
                blogPost={data?.data}
                readOnly={false}
              />
            </CardContent>
          </ScrollArea>
        </Card>
        <div className="h-full w-full max-w-80">
          <SideContainer
            blogPost={data?.data}
            authorData={data?.extra}
          />
        </div>
      </div>
    </div>
  )
}

ContentBlogPostsEdit.displayName = "ContentBlogPostsEdit";

export { ContentBlogPostsEdit };
