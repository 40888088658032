import { useMemo } from "react";
import {
  useBlogPostAsyncGeneration,
  useOnBookmarkConentTopic,
  useOnDeleteConentTopic,
  useOnGoToConentTopicBlogPost
} from "./ContentTopic.hooks";
import { ContentTopicUI } from "./ContentTopic.ui";

export const enum WritingStatus {
  IDLE = "idle",
  PENDING = "pending",
  DONE = "done",
}

export interface BaseContentTopicProps {
  topicId: number;
  blogPostId: number | null;
  contentRole: string;
  isBookmarked: boolean;
  name: string;
  focus: string;
  targetAudience: string;
  reason: string;
  status: WritingStatus;
  pendingStatusPhase: string;
  childrenTopics?: ClusterContentTopicsProps[];
  enabledControls?: ("generate" | "delete" | "bookmark")[];
  onTopicClick?: ((topicId: number) => void) | null;
}

export interface ClusterContentTopicsProps extends BaseContentTopicProps {}

export interface PillarContentTopicProps extends BaseContentTopicProps {
  childrenTopics: ClusterContentTopicsProps[];
}

export type ContentTopicProps = PillarContentTopicProps | ClusterContentTopicsProps;

const ContentTopic = ({
  topicId,
  blogPostId,
  contentRole,
  isBookmarked,
  name,
  focus,
  targetAudience,
  reason,
  status,
  pendingStatusPhase,
  childrenTopics,
  enabledControls,
  onTopicClick
}: ContentTopicProps) => {
  const normalizedEnablesControls = enabledControls || ["generate", "delete", "bookmark"]
  const onGenerateBlogPostAsync = useBlogPostAsyncGeneration({
    topicId,
    name,
    focus,
    targetAudience
  })
  const onDelete = useOnDeleteConentTopic({ topicId });
  const onBookmark = useOnBookmarkConentTopic({ topicId, isBookmarked });
  const onGoToBlogPost = useOnGoToConentTopicBlogPost({ blogPostId });
  const onTopicClickWrapper = useMemo(
    () => normalizedEnablesControls.length > 0 || !onTopicClick ?
      null : () => {
        onTopicClick(topicId)
      }, [onTopicClick, enabledControls, topicId])

  return (
    <ContentTopicUI
      contentRole={contentRole}
      isBookmarked={isBookmarked}
      name={name}
      focus={focus}
      targetAudience={targetAudience}
      reason={reason}
      status={status}
      pendingStatusPhase={pendingStatusPhase}
      childrenTopics={childrenTopics}
      enabledControls={normalizedEnablesControls}
      onGenerateBlogPost={onGenerateBlogPostAsync}
      onDelete={onDelete}
      onBookmark={onBookmark}
      onGoToBlogPost={onGoToBlogPost}
      onTopicClick={onTopicClickWrapper}
    />
  );
};

ContentTopic.displayName = "ContentTopic";

export { ContentTopic };
