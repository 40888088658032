import React, { useEffect } from "react";
import { useNavigate, } from "react-router-dom";
import { useOnboardingUpdate } from "../lib/onboarding";

const OnboardingWelcome = () => {
  const navigate = useNavigate();
  const onboardingNextStepUpdater = useOnboardingUpdate()

  useEffect(() => {
    const timeoutId = window.setTimeout(() => {
      navigate("/onboarding/create-workspace")
      if (onboardingNextStepUpdater) {
        onboardingNextStepUpdater("create-workspace")
      }
    }, 3000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [onboardingNextStepUpdater])

  return (
    <div className="flex flex-col w-full min-h-screen items-center justify-center">
      <div className="flex flex-col gap-4 items-center">
        <h1 className="text-6xl font-semibold">Welcome aboard 👋</h1>
        <span className="text-3xl font-regular text-muted-foreground">Let's grow your reach together.</span>
      </div>
    </div>
  )
}

export { OnboardingWelcome };
