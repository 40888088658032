import { useAuth } from '@clerk/clerk-react';
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter
} from "@repo/ui/components/ui/card";
import { Input } from "@repo/ui/components/ui/input";
import { Textarea } from "@repo/ui/components/ui/textarea";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CircleCheck, LoaderCircle, Sparkles } from 'lucide-react';
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import { useShallow } from 'zustand/react/shallow';
import { useOnboardingUpdate } from "../lib/onboarding";
import { fetcher, serializeError, useSocket } from '../services/api';
import { components } from '../services/api/openapi';
import { usePrefsStore } from '../store';

import { zodResolver } from "@hookform/resolvers/zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/components/ui/form";

const workspaceCreationFormSchema = z.object({
  website: z.string().url(),
})

type WorkspaceCreationFormType = z.infer<typeof workspaceCreationFormSchema>;

interface OnboardingCreateWorkspaceStartProps {
  onStart: (website: string) => void;
}

const OnboardingCreateWorkspaceStart = ({
  onStart
}: OnboardingCreateWorkspaceStartProps) => {
  const form = useForm<WorkspaceCreationFormType>({
    resolver: zodResolver(workspaceCreationFormSchema),
  })

  return (
    <div className="flex flex-col w-full min-h-screen gap-24 justify-center">
      <div className="flex flex-col gap-4 items-center">
        <h1 className="text-6xl font-semibold">Tell us about your product 💡</h1>
        <span className="text-3xl font-regular text-muted-foreground">Just drop your website — we’ll take it from there.</span>
      </div>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(({ website }) => {
            onStart(website)
          })}
          className="flex flex-col gap-6 items-center"
        >
          <FormField
            control={form.control}
            name="website"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    className="w-96"
                    placeholder="https://myawesomewebsite.com"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            variant="accent"
            className="flex gap-2 max-w-60"
          >
            <Sparkles className="size-5 animate-pulse" />
            <span className="animate-pulse">Make It Happen</span>
          </Button>
        </form>
      </Form>
    </div>
  )
}

interface Step {
  id: string;
  message: string;
  isDone: boolean;
}

interface StepStartedPayload {
  id: string;
  message: string;
  timestamp: number;
}
interface StepFinishedPayload {
  id: string;
  timestamp: number;
}

interface TaskStartedPayload {}
interface TaskFinishedPayload {
  message: string;
  workspace_id: number
}

interface WorkspaceCreationErrorPayload {
  message: string;
}

interface OnboardingCreateWorkspaceProgress {
  steps: Step[];
}

const OnboardingCreateWorkspaceProgress = ({
  steps
}: OnboardingCreateWorkspaceProgress) => {
  return (
    <div className="flex flex-col w-full min-h-screen gap-24 justify-center items-center">
      <Card className="bg-transparent border-0 shadow-none max-w-3xl">
        <CardContent className="p-0 space-y-2">
          {
            steps.map(s => (
              <div key={`step-${s.id}`} className="flex w-full justify-between items-center mr-4 gap-2">
                <span className="w-full text-left text-xl">{s.message}</span>
                {s.isDone ? (
                  <div>
                    <CircleCheck className="text-success-foreground" size="24" />
                  </div>
                ) : (
                  <div className="animate-spin">
                    <LoaderCircle size="22" />
                  </div>
                )}
              </div>
            ))
          }
        </CardContent>
      </Card>
    </div>
  )
}


const workspaceEditFormSchema = z.object({
  name: z.string().min(2).max(50),
  website: z.string().url(),
  description: z.string(),
  tone: z.string(),
  targetAudience: z.string(),
})

type WorkspaceEditFormType = z.infer<typeof workspaceEditFormSchema>;

interface OnboardingCreateWorkspaceDoneProps {
  workspaceId: number
  workspaceName: string
  workspaceWebsite: string
  workspaceDescription: string
  workspaceTone: string
  workspaceTargetAudience: string
  onSuccess: () => void
}

const OnboardingCreateWorkspaceDone = ({
  workspaceId,
  workspaceName,
  workspaceWebsite,
  workspaceDescription,
  workspaceTone,
  workspaceTargetAudience,
  onSuccess
}: OnboardingCreateWorkspaceDoneProps) => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();

  const form = useForm<WorkspaceEditFormType>({
    resolver: zodResolver(workspaceEditFormSchema),
    defaultValues: {
      name: workspaceName,
      website: workspaceWebsite,
      description: workspaceDescription,
      tone: workspaceTone,
      targetAudience: workspaceTargetAudience,
    }
  })

  const workspacessUpdate = useMutation({
    mutationFn: async (workspacePayload: components["schemas"]["UpdateWorkspaceRequest"]) => {
      const { data, error, response } = await fetcher.PATCH("/workspaces/{workspace_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            workspace_id: workspaceId
          }
        },
        body: workspacePayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get", "workspaces"] });
      queryClient.invalidateQueries({ queryKey: ["get", "workspaces", workspaceId] });
      onSuccess()
    },
    onError: (error) => {
      toast.error("Cannot update workspace",
        { description: `${error}` })
    },
  })

  const onSubmit = (values: WorkspaceEditFormType) => {
    const workspacePayload = {
      ...values,
      target_audience: values.targetAudience
    }
    workspacessUpdate.mutate(workspacePayload)
  }

  return (
    <div className="flex flex-col w-full min-h-screen gap-24 items-center justify-center">
      <div className="flex flex-col gap-4 items-center">
        <h1 className="text-6xl font-semibold">Off to a great start 🚀</h1>
        <span className="text-3xl font-regular text-muted-foreground">Here’s what we gathered — you can fine-tune it if needed.
        </span>
      </div>

      <Card className="w-full max-w-3xl">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-2"
          >

            <CardContent className="p-6 space-y-8">
              <div className="space-y-8">
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex gap-2 items-center">
                          <FormLabel>Name</FormLabel>
                        </div>

                        <FormControl>
                          <Input placeholder="My Awesome Product" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="website"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input type="hidden" placeholder="https://myawesomewebsite.com" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="description"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex gap-2 items-center">
                          <FormLabel>Description</FormLabel>
                        </div>

                        <FormControl>
                          <Textarea
                            placeholder="Tell us a little bit about your project"
                            className="resize-none h-24"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="space-y-8">
                <div className="space-y-2">
                  <h2 className="text-2xl font-semibold leading-none tracking-tight">Content Profile</h2>
                </div>
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="tone"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex gap-2 items-center">
                          <FormLabel>Tone</FormLabel>
                        </div>

                        <FormControl>
                          <Input placeholder="Professional" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="targetAudience"
                    render={({ field }) => (
                      <FormItem>
                        <div className="flex gap-2 items-center">
                          <FormLabel>Target audience</FormLabel>
                        </div>

                        <FormControl>
                          <Input placeholder="Founders" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

            </CardContent>

            <CardFooter className="flex justify-end">
              <Button type="submit" aria-label="start">
                Save
              </Button>
            </CardFooter>

          </form>
        </Form>
      </Card >
    </div>
  )
}


const OnboardingCreateWorkspace = () => {
  const { getToken } = useAuth();
  const socket = useSocket(getToken);
  const queryClient = useQueryClient();

  const [websiteUrl, setWebsiteUrl] = useState<string | null>(null);
  const [isStarted, setIsStarted] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [newWorkspaceId, setNewWorkspaceId] = useState<number | null>(null);
  const [setCurrentWorkspace] = usePrefsStore(
    useShallow((state) => [state.setCurrentWorkspace]),
  )

  const [steps, setSteps] = useState<Step[]>([]);
  const navigate = useNavigate();
  const onboardingNextStepUpdater = useOnboardingUpdate()

  useEffect(() => {
    if (isFinished) {
      if (onboardingNextStepUpdater) {
        onboardingNextStepUpdater("create-content-hub");
      }

      return navigate("/onboarding/create-content-hub");
    }
  }, [isFinished, onboardingNextStepUpdater])

  useEffect(() => {
    if (socket) {
      socket.on("workspace:create:error", (data: WorkspaceCreationErrorPayload) => {
        toast.error("Cannot create workspace",
          { description: `${data.message}` })
      })

      socket.on("task:started", (_: TaskStartedPayload) => {
        setIsStarted(true)
      })

      socket.on("task:finished", (data: TaskFinishedPayload) => {
        queryClient.invalidateQueries({ queryKey: ["get", "workspaces"] });
        queryClient.invalidateQueries({ queryKey: ["get", "workspaces", data.workspace_id] });
        queryClient.invalidateQueries({ queryKey: ["list", "features-usages"] });

        setCurrentWorkspace(data.workspace_id)

        window.setTimeout(() => {
          setNewWorkspaceId(data.workspace_id)
        }, [1000])
      })

      socket.on("step:started", (data: StepStartedPayload) => {
        setSteps(prevSteps => [
          ...prevSteps,
          { id: data.id, message: data.message, isDone: false }
        ])
      })

      socket.on("step:finished", (data: StepFinishedPayload) => {
        setSteps(prevSteps =>
          prevSteps.map(step =>
            step.id === data.id ? { ...step, isDone: true } : step
          )
        );
      })
    }
  }, [socket])

  useEffect(() => {
    if (socket && !isStarted && websiteUrl) {
      socket.emit("workspace:create", { website: websiteUrl })
    }
  }, [socket, websiteUrl, isStarted])

  const { data, status } = useQuery({
    queryKey: ["get", "workspaces", newWorkspaceId],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/workspaces/{workspace_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            workspace_id: newWorkspaceId as number
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: !!newWorkspaceId
  })

  useEffect(() => {
    if (status === "success" && data.data) {
      setCurrentWorkspace(data.data);
    }
  }, [status, data?.data]);

  const onStart = useCallback((websiteUrl: string) => {
    setWebsiteUrl(websiteUrl)
  }, [])

  const onFinish = useCallback(() => {
    setIsFinished(true)
  }, [])

  return (
    <>
      {
        status === "success" ? (
          <OnboardingCreateWorkspaceDone
            workspaceId={newWorkspaceId as number}
            workspaceName={data.data.name || ""}
            workspaceWebsite={data.data.website || ""}
            workspaceDescription={data.data.description || ""}
            workspaceTone={data.data.tone || ""}
            workspaceTargetAudience={data.data.target_audience || ""}
            onSuccess={onFinish}
          />
        ) : isStarted ? (
          <OnboardingCreateWorkspaceProgress steps={steps} />
        ) : (
          <OnboardingCreateWorkspaceStart onStart={onStart} />
        )
      }
    </>
  )
}

export { OnboardingCreateWorkspace };
