import { useAuth, useUser } from '@clerk/clerk-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from "react";
import { fetcher, serializeError } from '../services/api';


const useOnboardingUpdate = () => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();
  const queryClient = useQueryClient();
  const [onboardingId, setOnboardingId] = useState<number | null>(null)

  const { data, status } = useQuery({
    queryKey: ['get', 'onboardings'],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/onboardings", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  useEffect(() => {
    if (status === "success" && data) {
      setOnboardingId(data.data[0]?.id || null)
    }
  }, [status, data])

  const onboardingUpdate = useMutation({
    mutationFn: async ({
      onboardingId, nextStep
    }: {
      onboardingId: number, nextStep: string
    }) => {
      const { data, error, response } = await fetcher.PATCH("/onboardings/{onboarding_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            onboarding_id: onboardingId
          }
        },
        body: {
          next_step: nextStep
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["get", "onboardings"] });
      queryClient.invalidateQueries({ queryKey: ["get", "onboardings", data.data.id] });
    },
  })

  const nextStepUpdater = useMemo(() => {
    if (onboardingId) {
      return (nextStep: string) => {
        onboardingUpdate.mutate({
          onboardingId,
          nextStep
        })
      }
    } else {
      return null
    }
  }, [onboardingId])

  return nextStepUpdater
}

export { useOnboardingUpdate };
