import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@repo/ui/components/ui/accordion";
import { ContentTopic } from "../contentTopic";
import { ContentThemeProps } from "./ContentTheme";

interface ContentThemeUIProps extends ContentThemeProps {}

const ContentThemeUI = ({
  name,
  description,
  topics,
  enabledControls,
  onTopicClick,
}: ContentThemeUIProps) => {
  return (
    <Accordion type="single" collapsible className="w-full max-w-3xl">
      <AccordionItem value="value-1" className="border px-4 py-2 rounded-lg bg-white">

        <AccordionTrigger className="hover:no-underline text-left flex gap-32">
          <div className="flex flex-col gap-2">
            <h3 className="font-semibold text-lg">{name}</h3>
            <p className="text-muted-foreground text-sm">{description}</p>
          </div>
        </AccordionTrigger>

        <AccordionContent className="flex flex-col gap-2 pt-4 pl-4">
          {topics.map(t => (
            <ContentTopic
              key={t.topicId}
              {...t}
              enabledControls={enabledControls}
              onTopicClick={onTopicClick}
            />
          ))}
        </AccordionContent>

      </AccordionItem >
    </Accordion >
  );
};

ContentThemeUI.displayName = "ContentThemeUI";

export { ContentThemeUI };
