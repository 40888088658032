import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@repo/ui-core";
import { cva } from "class-variance-authority";
import { useSearchParams } from 'react-router-dom';


import React from "react";


interface PaginationWidgetProps {
  pageQueryParam: string;
  currentPage: number;
  maxPage: number;
}

const edgeButtonVariants = cva(
  "",
  {
    variants: {
      isEnabled: {
        false: "text-muted-foreground pointer-events-none",
        true: "",
      },
    },
    defaultVariants: {
      isEnabled: false,
    }
  }
)

const buildTo = (
  searchParams: URLSearchParams,
  pageQueryParam: string,
  targetPage: number
): string => {
  const updatedParams = new URLSearchParams(searchParams);
  updatedParams.set(pageQueryParam, targetPage.toString());
  return `?${updatedParams.toString()}`;
}

const PaginationWidget: React.FC<PaginationWidgetProps> = ({
  pageQueryParam,
  currentPage,
  maxPage,
}) => {
  const [searchParams] = useSearchParams();

  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem
          className={edgeButtonVariants({ isEnabled: currentPage > 1 })}
        >
          <PaginationPrevious to={buildTo(searchParams, pageQueryParam, currentPage - 1)} />
        </PaginationItem>

        {
          currentPage > 2 && (
            <PaginationItem>
              <PaginationLink to={buildTo(searchParams, pageQueryParam, 1)}>1</PaginationLink>
            </PaginationItem>
          )
        }

        {
          currentPage > 3 && (
            <PaginationItem>
              <PaginationEllipsis />
            </PaginationItem>
          )
        }

        {
          currentPage > 1 && (
            <PaginationItem>
              <PaginationLink to={buildTo(searchParams, pageQueryParam, currentPage - 1)}>{currentPage - 1}</PaginationLink>
            </PaginationItem>
          )
        }

        <PaginationItem>
          <PaginationLink to={buildTo(searchParams, pageQueryParam, currentPage)} isActive>{currentPage}</PaginationLink>
        </PaginationItem>

        {
          (maxPage - currentPage) > 0 && (
            <PaginationItem>
              <PaginationLink to={buildTo(searchParams, pageQueryParam, currentPage + 1)}>{currentPage + 1}</PaginationLink>
            </PaginationItem>
          )
        }

        {
          (maxPage - currentPage) > 2 && (
            <PaginationItem>
              <PaginationEllipsis />
            </PaginationItem>
          )
        }

        {
          (maxPage - currentPage) > 1 && (
            <PaginationItem>
              <PaginationLink to={buildTo(searchParams, pageQueryParam, maxPage)}>{maxPage}</PaginationLink>
            </PaginationItem>
          )
        }

        <PaginationItem
          className={edgeButtonVariants({ isEnabled: currentPage < maxPage })}
        >
          <PaginationNext to={buildTo(searchParams, pageQueryParam, currentPage + 1)} />
        </PaginationItem>
      </PaginationContent>
    </Pagination >
  )
}

PaginationWidget.displayName = "PaginationWidget";

export { PaginationWidget };
