import { useAuth } from '@clerk/clerk-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { toast } from "sonner";
import { fetcher, serializeError } from '../../services/api';
import { components } from '../../services/api/openapi';
import { usePrefsStore } from '../../store';
import { BlogPostStatusCTAUI } from "./BlogPostStatusCTA.ui";

interface BlogPostStatusCTAProps {
  blogPostId: number;
  isPublished: boolean;
  publishedUrl: string;
}

const BlogPostStatusCTA = ({
  blogPostId,
  isPublished,
  publishedUrl
}: BlogPostStatusCTAProps) => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const currentWorkspace = usePrefsStore((state) => state.currentWorkspace)

  const blogPostUpdate = useMutation({
    mutationFn: async (blogPostPayload: components["schemas"]["UpdateBlogPostRequest"]) => {
      const { data, error, response } = await fetcher.PATCH("/blog-posts/{blog_post_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            blog_post_id: blogPostId,
          }
        },
        body: blogPostPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["get", "blogPosts", currentWorkspace?.id] });

      toast.success("BlogPost marked as published!",
        { description: data.data.published_url })
    },
    onError: (error) => {
      toast.error("Cannot update BlogPost",
        { description: `${error}` })
    },
  })

  const onMarkAsPublished = useCallback((website: string) => {
    blogPostUpdate.mutate({ published_url: website, is_published: true })
  }, [blogPostUpdate])

  return (
    <BlogPostStatusCTAUI
      isPublished={isPublished}
      publishedUrl={publishedUrl}
      onMarkAsPublished={onMarkAsPublished}
    />
  )
}

BlogPostStatusCTA.displayName = "BlogPostStatusCTA";

export { BlogPostStatusCTA };
