import { useAuth, useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';
import React from "react";
import { useNavigate } from "react-router-dom";
import { ContentTheme, Spinner, WritingStatus } from "../organisms/";
import { fetcher, serializeError } from '../services/api';
import { usePrefsStore } from '../store';

interface ContentHubProps {}

const ContentHub: React.FC<ContentHubProps> = () => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();
  const currentWorkspace = usePrefsStore((state) => state.currentWorkspace)
  const navigate = useNavigate();

  const { data, error, status } = useQuery({
    queryKey: ["list", "content-topics", currentWorkspace?.id],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/content-topics", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          query: {
            expand: [
              "content_theme",
              "blog_post"
              // TODO: Initially this was querying /content-themes but
              // there are some issues in fetching .blog_post in the same query
              //
              // "content_topics.blog_post"
              //
              // In general with nested relationships there are some issues
              // in how the "Full" schema is built.
              // So I used /content-topics to avoid needing nested "expand".
            ],
            workspace_id: currentWorkspace?.id,
            page_size: 500
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  if (data.data.length === 0) {
    return navigate("/content/content-hub/generate");
  }

  const themes = {
    // 1: {
    //   "id": ...,
    //   "name": ...,
    //   "description": ...,
    //   "childrenTopics": ...
    // }
  }
  data.data.forEach(topic => {
    if (!themes[topic.content_theme_id]) {
      themes[topic.content_theme_id] = {
        id: topic.content_theme_id,
        name: topic.content_theme?.name,
        description: topic.content_theme?.description,
        childrenTopics: data.data
          .filter(innerTopic => innerTopic.content_role === "pillar")
          .filter(innerTopic => innerTopic.content_theme_id === topic.content_theme_id)
          .sort((a, b) => a.id - b.id)
          .map(innerTopic => ({
            topicId: innerTopic.id,
            blogPostId: innerTopic.blog_post?.id,
            contentRole: innerTopic.content_role,
            isBookmarked: innerTopic.is_bookmarked,
            name: innerTopic.name,
            focus: innerTopic.focus,
            targetAudience: innerTopic.target_audience,
            reason: innerTopic.reason,
            status: innerTopic.blog_post ? (
              innerTopic.blog_post.status === "done" ?
                WritingStatus.DONE : WritingStatus.PENDING
            ) : WritingStatus.IDLE,
            pendingStatusPhase: innerTopic.blog_post?.status,
            childrenTopics: data.data
              .filter(innerInnerTopic => innerInnerTopic.content_role === "cluster")
              .filter(innerInnerTopic => innerInnerTopic.parent_content_topic_id === innerTopic.id)
              .sort((a, b) => a.id - b.id)
              .map(innerInnerTopic => ({
                topicId: innerInnerTopic.id,
                blogPostId: innerInnerTopic.blog_post?.id,
                contentRole: innerInnerTopic.content_role,
                isBookmarked: innerInnerTopic.is_bookmarked,
                name: innerInnerTopic.name,
                focus: innerInnerTopic.focus,
                targetAudience: innerInnerTopic.target_audience,
                reason: innerInnerTopic.reason,
                status: innerInnerTopic.blog_post ? (
                  innerInnerTopic.blog_post.status === "done" ?
                    WritingStatus.DONE : WritingStatus.PENDING
                ) : WritingStatus.IDLE,
                pendingStatusPhase: innerInnerTopic.blog_post?.status,
              }))
          }))
      }
    }
  })

  const bookmarkedTopics = data.data
    .filter(innerTopic => innerTopic.content_role === "pillar")
    .filter(innerTopic => innerTopic.is_bookmarked)
    .sort((a, b) => a.id - b.id)
    .map(innerTopic => ({
      topicId: innerTopic.id,
      blogPostId: innerTopic.blog_post?.id,
      contentRole: innerTopic.content_role,
      isBookmarked: innerTopic.is_bookmarked,
      name: innerTopic.name,
      focus: innerTopic.focus,
      targetAudience: innerTopic.target_audience,
      reason: innerTopic.reason,
      status: innerTopic.blog_post ? (
        innerTopic.blog_post.status === "done" ?
          WritingStatus.DONE : WritingStatus.PENDING
      ) : WritingStatus.IDLE,
      pendingStatusPhase: innerTopic.blog_post?.status,
      childrenTopics: data.data
        .filter(innerInnerTopic => innerInnerTopic.content_role === "cluster")
        .filter(innerInnerTopic => innerInnerTopic.is_bookmarked)
        .filter(innerInnerTopic => innerInnerTopic.parent_content_topic_id === innerTopic.id)
        .sort((a, b) => a.id - b.id)
        .map(innerInnerTopic => ({
          topicId: innerInnerTopic.id,
          blogPostId: innerInnerTopic.blog_post?.id,
          contentRole: innerInnerTopic.content_role,
          isBookmarked: innerInnerTopic.is_bookmarked,
          name: innerInnerTopic.name,
          focus: innerInnerTopic.focus,
          targetAudience: innerInnerTopic.target_audience,
          reason: innerInnerTopic.reason,
          status: innerInnerTopic.blog_post ? (
            innerInnerTopic.blog_post.status === "done" ?
              WritingStatus.DONE : WritingStatus.PENDING
          ) : WritingStatus.IDLE,
          pendingStatusPhase: innerInnerTopic.blog_post?.status,
        }))
    }))

  return (
    <div className="flex flex-col w-full justify-center items-center gap-2">
      {bookmarkedTopics.length > 0 && (
        <ContentTheme
          key="bookmarked-topics"
          name="Bookmarked"
          description="Your bookmarked topics."
          topics={bookmarkedTopics}
        />
      )}
      {
        Object.values(themes)
          .sort((a, b) => a.id - b.id)
          .map(theme => (
            <ContentTheme
              key={theme.id}
              name={theme.name}
              description={theme.description}
              topics={theme.childrenTopics}
            />
          ))
      }
    </div>
  )
}

ContentHub.displayName = "ContentHub";

export { ContentHub };
