import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@repo/ui/components/ui/accordion";

import { Badge } from "@repo/ui/components/ui/badge";
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
} from "@repo/ui/components/ui/card";
import { Separator } from "@repo/ui/components/ui/separator";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@repo/ui/components/ui/tooltip";
import { cn } from "@repo/ui/lib/utils";
import { CircleArrowRight, CircleHelp, Focus, PenLine, Star, Users, X } from 'lucide-react';
import { useCallback, useMemo } from "react";
import { Spinner } from "..";
import { BaseContentTopicProps, ContentTopic, WritingStatus } from "./ContentTopic";

interface IdleContentTopicControlsProps {
  isBookmarked: boolean;
  enabledControls: ("generate" | "delete" | "bookmark")[];
  onGenerateBlogPost: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  onDelete: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  onBookmark: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}

const IdleContentTopicControls = ({
  isBookmarked,
  enabledControls,
  onGenerateBlogPost,
  onDelete,
  onBookmark,
}: IdleContentTopicControlsProps) => {
  return (
    <div className="flex flex-col items-center gap-2">
      <TooltipProvider delayDuration={200}>
        {
          enabledControls.includes("generate") && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-12 w-12 hover:bg-primary/40"
                  onClick={onGenerateBlogPost}
                >
                  <PenLine className="w-8 h-8" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Generate blog post</p>
              </TooltipContent>
            </Tooltip>
          )
        }

        {
          enabledControls.includes("delete") && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-12 w-12 text-destructive hover:text-destructive hover:bg-destructive/10"
                  onClick={onDelete}
                >
                  <X className="w-8 h-8" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Delete topic</p>
              </TooltipContent>
            </Tooltip>
          )
        }

        {
          enabledControls.includes("bookmark") && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="icon"
                  className={cn(
                    "h-12 w-12",
                    isBookmarked
                      ? "text-yellow-300 hover:text-yellow-300 hover:bg-yellow-100/90"
                      : "text-muted-foreground/40 hover:text-yellow-300 hover:bg-yellow-100/90"
                  )}
                  onClick={onBookmark}
                >
                  {isBookmarked ? (
                    <Star className="w-8 h-8 fill-current" />
                  ) : (
                    <Star className="w-8 h-8" />
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>{isBookmarked ? "Remove bookmark" : "Bookmark topic"}</p>
              </TooltipContent>
            </Tooltip>
          )
        }

      </TooltipProvider>
    </div>
  )
};

interface PendingContentTopicControlsProps {
  pendingStatusPhase: string;
}

const PendingContentTopicControls = ({
  pendingStatusPhase
}: PendingContentTopicControlsProps) => {
  return (
    <div className="flex flex-col gap-2 items-center">
      <Badge variant="outline">
        {pendingStatusPhase}
      </Badge>
      <Spinner />
    </div>
  )
};

interface DoneContentTopicControlsProps {
  onGoToBlogPost: (ev: React.MouseEvent<HTMLButtonElement>) => void;
}

const DoneContentTopicControls = ({
  onGoToBlogPost,
}: DoneContentTopicControlsProps) => {
  return (
    <div className="flex flex-col gap-2 items-center">
      <TooltipProvider delayDuration={200}>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              className="h-12 w-12 text-green-400 hover:text-green-400 hover:bg-green-200/40"
              onClick={onGoToBlogPost}
            >
              <CircleArrowRight className="h-8 w-8" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p>Go to blog post</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
};


interface ContentTopicCoreUIProps extends Omit<ContentTopicUIProps, "childrenTopics"> {}

const ContentTopicCoreUI = ({
  contentRole,
  isBookmarked,
  name,
  focus,
  targetAudience,
  reason,
  status,
  pendingStatusPhase,
  enabledControls,
  onGenerateBlogPost,
  onDelete,
  onBookmark,
  onGoToBlogPost,
  onTopicClick
}: ContentTopicCoreUIProps) => {
  const badgeStyle = contentRole.toLowerCase() === "pillar" ?
    "bg-blue-600 text-background" : "bg-cyan-400 text-foreground"

  const onGenerateBlogPostWrapper = useCallback((ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation()
    onGenerateBlogPost();
  }, [onDelete])

  const onDeleteWrapper = useCallback((ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation()
    onDelete();
  }, [onDelete])

  const onBookmarkWrapper = useCallback((ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation()
    onBookmark();
  }, [onDelete])

  const onGoToBlogPostWrapper = useCallback((ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation()
    onGoToBlogPost();
  }, [onDelete])

  const onTopicClickWrapper = useMemo(() =>
    onTopicClick ? (ev: React.MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation()
      onTopicClick();
    } : null, [onTopicClick])

  return (
    <Card className={`flex max-w-3xl w-full text-left ${onTopicClickWrapper ? "hover:cursor-pointer" : ""}`} onClick={onTopicClickWrapper}>
      <CardContent className="flex-1 p-6">
        <Badge className={`mb-2 ${badgeStyle}`}>
          {contentRole}
        </Badge>

        <div className="flex flex-col gap-4">
          <div>
            <h3 className="font-semibold text-lg">{name}</h3>
            <div className="flex flex-col gap-2 text-sm text-muted-foreground mt-1">
              <div className="flex items-center gap-4">
                <div className="flex gap-1">
                  <Focus className="w-5 h-5" />
                  <span className="font-medium">Focus:</span>
                </div>
                <span>{focus}</span>
              </div>
              <div className="flex items-center gap-4">
                <div className="flex gap-1">
                  <Users className="w-5 h-5" />
                  <span className="font-medium">Target:</span>
                </div>
                <span>{targetAudience}</span>
              </div>
            </div>
          </div>

          <Card className="bg-muted/50">
            <CardContent className="p-4 flex flex-col gap-2">
              <div className="flex gap-1 text-sm font-medium">
                <CircleHelp className="w-5 h-5" /><span>Why?</span>
              </div>
              <p className="text-sm italic">{reason}</p>
            </CardContent>
          </Card>
        </div>
      </CardContent>

      {
        enabledControls.length > 0 && (
          <>
            <Separator orientation="vertical" className="h-auto my-6" />

            <CardContent
              className="w-24 p-4 flex flex-col justify-center gap-2 hover:cursor-default"
              onClick={(ev: React.MouseEvent<HTMLButtonElement>) => {
                ev.stopPropagation()
              }}
            >
              {
                status === WritingStatus.IDLE ? (
                  <IdleContentTopicControls
                    isBookmarked={isBookmarked}
                    enabledControls={enabledControls}
                    onGenerateBlogPost={onGenerateBlogPostWrapper}
                    onDelete={onDeleteWrapper}
                    onBookmark={onBookmarkWrapper}
                  />
                ) : status === WritingStatus.PENDING ? (
                  <PendingContentTopicControls
                    pendingStatusPhase={pendingStatusPhase}
                  />
                ) : (
                  <DoneContentTopicControls
                    onGoToBlogPost={onGoToBlogPostWrapper}
                  />
                )
              }
            </CardContent>
          </>
        )
      }
    </Card>
  )
}


interface ContentTopicUIProps extends Omit<
  BaseContentTopicProps, "topicId" | "blogPostId" | "onTopicClick"
> {
  onGenerateBlogPost: () => void;
  onDelete: () => void;
  onBookmark: () => void;
  onGoToBlogPost: () => void;
  onTopicClick: (() => void) | null;
}

const ContentTopicUI = (args: ContentTopicUIProps) => {
  const { childrenTopics } = args;
  if (childrenTopics && childrenTopics.length > 0) {
    return (
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem
          value="value-1"
          className="gap-1 border-none px-4 py-2 rounded-lg"
        >
          <AccordionTrigger className="hover:no-underline text-left flex gap-4">

            <ContentTopicCoreUI {...args} />

          </AccordionTrigger>

          <AccordionContent className="flex flex-col gap-2 pt-0 pl-12">
            {
              childrenTopics.map(ct => (
                <ContentTopic key={ct.topicId} {...ct} />
              ))
            }
          </AccordionContent>


        </AccordionItem>
      </Accordion>
    )
  } else {
    return <ContentTopicCoreUI {...args} />
  }
};

ContentTopicUI.displayName = "ContentTopicUI";

export { ContentTopicUI };
