import { useAuth, useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from '../assets/logo_transparent_bg.png';
import { Spinner } from "../organisms/";
import { fetcher, serializeError } from '../services/api';


const Onboarding = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();

  const { data, error, status } = useQuery({
    queryKey: ['get', 'onboardings'],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/onboardings", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  useEffect(() => {
    if (status === "success" && data) {
      const nextStep = data.data[0]?.next_step
      if (nextStep === "done") {
        navigate("/")
      }
    }
  }, [status, data])


  if (location.pathname === "/onboarding") {
    return <Navigate to="/onboarding/welcome" replace />;
  }

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  return (
    <div className="flex flex-col w-full min-h-screen">
      <div className="flex items-center gap-1">
        <img src={logo} className="size-12" alt="Logo" />
        <span className="text-2xl font-semibold">HeyEcho</span>
      </div>

      <Outlet />
    </div>
  )
}

export { Onboarding };
