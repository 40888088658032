import { useAuth, useUser } from '@clerk/clerk-react';
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import { Form } from "@repo/ui/components/ui/form";
import { useQuery } from '@tanstack/react-query';
import { CircleCheck, LoaderCircle, Sparkles } from 'lucide-react';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Spinner } from "../organisms/";
import { fetcher, serializeError, useSocket } from '../services/api';
import { usePrefsStore } from '../store';

import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";


const formSchema = z.object({})

type FormType = z.infer<typeof formSchema>;

interface FormCardProps {}

const FormCard: React.FC<FormCardProps> = () => {
  const { getToken } = useAuth();
  const socket = useSocket(getToken);
  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
  })
  const currentWorkspace = usePrefsStore((state) => state.currentWorkspace)

  return (
    <Card className="max-w-lg w-full">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(() => {
            if (socket) {
              socket.emit("content-themes:create", {
                workspace_id: currentWorkspace?.id
              })
            }
          })}
          className="flex flex-col gap-6"
        >
          <CardHeader className="text-center">
            <CardTitle className="flex flex-col gap-1">
              <span>Generate Your Content Hub &</span>
              <span>Build a Winning Blog Strategy</span>
            </CardTitle>

            <CardDescription>
              Don’t just write—strategize.
            </CardDescription>
          </CardHeader>
          <CardContent className="text-center">
            A Content Hub organizes your blog strategy into Themes, Pillars, and Clusters—creating a scalable system that strengthens SEO, boosts engagement, and drives results.
          </CardContent>
          <CardFooter className="flex items-center justify-center">
            <Button
              type="submit"
              variant="accent"
              className="flex gap-2"
            >
              <Sparkles className="size-5 animate-pulse" />
              <span className="animate-pulse">Make It Happen</span>
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  )
}


interface Step {
  id: string;
  message: string;
  isDone: boolean;
}


interface StepStartedPayload {
  id: string;
  message: string;
  timestamp: number;
}
interface StepFinishedPayload {
  id: string;
  timestamp: number;
}

interface WorkflowStartedPayload {}
interface WorkflowFinishedPayload {
  message: string;
  workspace_id: number
}

interface ContentHubGenerationErrorPayload {
  message: string;
}

interface ContentHubGenerateProps {}

const ContentHubGenerate: React.FC<ContentHubGenerateProps> = () => {
  const { getToken } = useAuth();
  const socket = useSocket(getToken);
  const { isSignedIn } = useUser();
  const queryClient = useQueryClient();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [steps, setSteps] = useState<Step[]>([]);
  const currentWorkspace = usePrefsStore((state) => state.currentWorkspace)
  const navigate = useNavigate();

  useEffect(() => {
    if (socket) {
      socket.on("content-themes:create:error", (data: ContentHubGenerationErrorPayload) => {
        toast.error("Cannot generate content hub",
          { description: `${data.message}` })
        window.setTimeout(() => {
          return navigate("/content/content-hub");
        }, [1000])
      })

      socket.on("workflow:started", (_: WorkflowStartedPayload) => {
        setIsSubmitted(true)
      })

      socket.on("workflow:finished", (data: WorkflowFinishedPayload) => {
        toast.success(data.message);
        queryClient.invalidateQueries({
          queryKey: ["list", "content-themes", currentWorkspace?.id]
        });

        window.setTimeout(() => {
          return navigate("/content/content-hub");
        }, [1000])
      })

      socket.on("step:started", (data: StepStartedPayload) => {
        setSteps(prevSteps => [
          ...prevSteps,
          { id: data.id, message: data.message, isDone: false }
        ])
      })

      socket.on("step:finished", (data: StepFinishedPayload) => {
        setSteps(prevSteps =>
          prevSteps.map(step =>
            step.id === data.id ? { ...step, isDone: true } : step
          )
        );
      })
    }
  }, [socket, currentWorkspace])

  const { data, error, status } = useQuery({
    queryKey: ["list", "content-themes", currentWorkspace?.id],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/content-themes", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          query: {
            workspace_id: currentWorkspace?.id,
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  if (data.data.length > 0) {
    return navigate("/content/content-hub");
  }

  return (
    <div className="flex flex-col w-full h-full justify-center items-center gap-2">
      {
        isSubmitted ? (
          <Card className="bg-transparent border-0 shadow-none">
            <CardContent className="p-0 space-y-2">
              {
                steps.map(s => (
                  <div key={`step-${s.id}`} className="flex w-full justify-between items-center mr-4 gap-2">
                    <span className="w-full text-left text-xl">{s.message}</span>
                    {s.isDone ? (
                      <div>
                        <CircleCheck className="text-success-foreground" size="24" />
                      </div>
                    ) : (
                      <div className="animate-spin">
                        <LoaderCircle size="22" />
                      </div>
                    )}
                  </div>
                ))
              }
            </CardContent>
          </Card>
        ) : (
          <FormCard />
        )
      }
    </div>
  )
}

ContentHubGenerate.displayName = "ContentHubGenerate";

export { ContentHubGenerate };
