import { useAuth } from '@clerk/clerk-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from "react-router-dom";
import rehypeHighlight from 'rehype-highlight';
import remarkGfm from 'remark-gfm';
import { toast } from "sonner";
import { fetcher, serializeError } from '../services/api';
import { components } from '../services/api/openapi';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@repo/ui/components/ui/accordion";
import { Badge } from "@repo/ui/components/ui/badge";
import { Button } from "@repo/ui/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@repo/ui/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  type CarouselApi,
} from "@repo/ui/components/ui/carousel";
import { Input } from "@repo/ui/components/ui/input";
import { Label } from "@repo/ui/components/ui/label";
import { Progress } from "@repo/ui/components/ui/progress";
import { ScrollArea } from "@repo/ui/components/ui/scroll-area";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@repo/ui/components/ui/select";
import { Separator } from "@repo/ui/components/ui/separator";
import { CircleCheck, ListTodo, LoaderCircle, WandSparkles } from 'lucide-react';
import React, { useCallback, useEffect, useState } from "react";
import { useSocket } from "../services/api";
import { usePrefsStore } from '../store';


interface useBlogPostLiveProgressIn {
  useToastNotifications: boolean
  onFinish?: ((blogPostId: number) => void) | null
}

interface useBlogPostLiveProgressOut {
  workflowStarted: boolean;
  totalTasks: number;
  phase: string;
  tasksLog: TasksLogCollection;
  blogPost: BlogPost | null;
}

const useBlogPostLiveProgress = ({
  useToastNotifications,
  onFinish,
}: useBlogPostLiveProgressIn): useBlogPostLiveProgressOut => {
  const { getToken } = useAuth();
  const socket = useSocket(getToken);
  const queryClient = useQueryClient();

  const [workflowStarted, setWorkflowStarted] = useState(false)
  const [totalTasks, setTotalTasks] = useState(0)
  const [phase, setPhase] = useState("initializing")
  const [tasksLog, setTasksLog] = useState<TasksLogCollection>({});
  const [blogPost, setBlogPost] = useState<BlogPost | null>(null);

  useEffect(() => {
    if (socket) {
      socket.on("workflow:start:error", (data: WorkflowStartErrorPayload) => {
        if (useToastNotifications) {
          toast.error("Cannot create blog post", { description: `${data.message}` })
        }
      })

      socket.on("database:saved", async (payload: DatabaseSavedPayload) => {
        console.log("database:saved", payload)
        await queryClient.ensureQueryData({
          queryKey: ["get", "blogPosts", payload.blog_post_id],
          queryFn: async () => {
            const { data, error, response } = await fetcher.GET("/blog-posts/{blog_post_id}", {
              headers: {
                Authorization: `Bearer ${await getToken()}`,
                'Content-Type': 'application/json',
              },
              params: {
                path: {
                  blog_post_id: payload.blog_post_id
                },
                query: {
                  expand: ["author", "sources"]
                }
              }
            })

            if (error) {
              throw serializeError(error, response.status)
            }

            return data;
          },
        });

        queryClient.invalidateQueries({ queryKey: ["list", "features-usages"] });
        if (onFinish) {
          onFinish(payload.blog_post_id)
        }
      })

      socket.on("workflow:started", (payload: WorkflowStartedPayload) => {
        console.log("workflow:started", payload)
        setTotalTasks(payload.total_tasks);
        setWorkflowStarted(true)
      })

      socket.on("workflow:finished", (payload: WorkflowFinishedPayload) => {
        console.log("workflow:finished", payload)
        setPhase("done")
      })

      socket.on("task:started", (payload: TaskStartedPayload) => {
        console.log("task:started", payload)
        setPhase(payload.phase)
        setTasksLog(tasksLog => ({
          ...tasksLog,
          [payload.task_id]: {
            startedAt: payload.timestamp,
            endedAt: null,
            message: payload.message,
            totalSubTasks: payload.total_subtasks,
            subTasks: {},
          }
        }))
      })

      socket.on("task:finished", (payload: TaskFinishedPayload) => {
        console.log("task:finished", payload)
        setTasksLog(tasksLog => ({
          ...tasksLog,
          [payload.task_id]: {
            ...tasksLog[payload.task_id],
            endedAt: payload.timestamp,
          }
        }))
      })

      socket.on("subtask:started", (payload: SubTaskPayload) => {
        console.log("subtask:started", payload)
        setTasksLog(tasksLog => ({
          ...tasksLog,
          [payload.task_id]: {
            ...tasksLog[payload.task_id],
            subTasks: {
              ...tasksLog[payload.task_id]?.subTasks,
              [payload.subtask_id]: {
                startedAt: payload.timestamp,
                endedAt: null,
                startMessage: payload.message,
                endMessage: null
              }
            },
          }
        }))
      })

      socket.on("subtask:finished", (payload: SubTaskPayload) => {
        console.log("subtask:finished", payload)
        setTasksLog(tasksLog => ({
          ...tasksLog,
          [payload.task_id]: {
            ...tasksLog[payload.task_id],
            subTasks: {
              ...tasksLog[payload.task_id]?.subTasks,
              [payload.subtask_id]: {
                ...tasksLog[payload.task_id]?.subTasks[payload.subtask_id],
                endedAt: payload.timestamp,
                endMessage: payload.message,
              }
            },
          }
        }))
      })

      socket.on("canvas:outline:write", (payload: CanvasOutlineWritePayload) => {
        console.log("canvas:outline:write", payload)
        setBlogPost(blogPost => ({
          title: payload.title,
          framing: {
            hook: "",
            conclusion_heading: payload.conclusion_heading,
            conclusion_content: ""
          },
          body: {
            sections: payload.main_body_sections.map((s, i) => ({
              index: i,
              heading: s.section_heading,
              content: ""
            }))
          },
          heroImage: ""
        }))
      })

      socket.on("canvas:sections:write", (payload: CanvasSectionsWritePayload) => {
        console.log("canvas:sections:write", payload)
        setBlogPost(blogPost => ({
          ...blogPost,
          body: {
            sections: blogPost.body.sections.map(s => ({
              ...s,
              content: payload.main_body_sections[s.index]?.section_content
            }))
          },
        }))
      })

      socket.on("canvas:framing:write", (payload: CanvasFramingWritePayload) => {
        console.log("canvas:framing:write", payload)
        setBlogPost(blogPost => ({
          ...blogPost,
          framing: {
            ...blogPost.framing,
            hook: payload.hook,
            conclusion_content: payload.conclusion_content
          }
        }))
      })

      socket.on("canvas:media:hero", (payload: CanvasHeroImagePayload) => {
        console.log("canvas:media:hero", payload)
        setBlogPost(blogPost => ({
          ...blogPost,
          heroImage: payload.hero_image
        }))
      })
    }
  }, [socket])

  return {
    workflowStarted,
    totalTasks,
    phase,
    tasksLog,
    blogPost,
  }
}


interface BlogPostFraming {
  hook: string;
  conclusion_heading: string;
  conclusion_content: string;
}

interface BlogPostBody {
  sections: {
    index: number;
    heading: string;
    content: string;
  }[];
}

interface BlogPost {
  title: string;
  framing: BlogPostFraming;
  body: BlogPostBody;
  heroImage: string;
}


interface CanvasProps {
  blogPost: BlogPost;
}


const Canvas: React.FC<CanvasProps> = ({
  blogPost,
}) => {
  const title = `# ${blogPost.title}`;
  const hook = blogPost.framing.hook;
  const bodySections = blogPost.body.sections
    .map(s => `## ${s.heading}\n${s.content}`)
    .join("\n")
  const conclusion = `## ${blogPost.framing.conclusion_heading}\n${blogPost.framing.conclusion_content}`
  const heroImage = blogPost.heroImage ? `![Hero image](${blogPost.heroImage})` : ""
  const mdContent = `${title}\n${heroImage}\n${hook}\n${bodySections}\n${conclusion}`

  return (
    <ReactMarkdown
      className="max-w-none prose pb-40"
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeHighlight]}
      components={{
        img: ({ node, ...props }) => {
          return (
            <img src={`data:image/png;base64,${props.src}`} style={{ maxWidth: "100%" }} alt={props.alt || "Image"} />
          )
        },
      }}
    >
      {mdContent}
    </ReactMarkdown>
  )
}


interface SubTask {
  startedAt: number;
  endedAt: number | null;
  startMessage: string;
  endMessage: string | null;
}

interface Task {
  startedAt: number;
  endedAt: number | null;
  message: string;
  totalSubTasks: number;
  subTasks: Record<string, SubTask>;
}

type TasksLogCollection = Record<string, Task>;

interface TaskLogProps {
  tasksLog: TasksLogCollection;
}

const TasksLog: React.FC<TaskLogProps> = ({
  tasksLog
}) => {
  const [allTasksIds, setAllTasksIds] = useState<string[]>([]);
  const [doneTasksIds, setDoneTasksIds] = useState<string[]>([]);
  const [expandedTasks, setExpandedTasks] = useState<string[]>([])

  useEffect(() => {
    Object.entries(tasksLog).map(([taskId, taskLog]) => {
      if (!allTasksIds.includes(taskId)) {
        setAllTasksIds([...allTasksIds, taskId])
        setExpandedTasks(
          (prevExpandedTasks) => [...prevExpandedTasks, `task-${taskId}`])
      }

      if (taskLog.endedAt !== null && !doneTasksIds.includes(taskId)) {
        setDoneTasksIds([...doneTasksIds, taskId])

        window.setTimeout(() => {
          setExpandedTasks(
            (prevExpandedTasks) => [...prevExpandedTasks]
              .filter((id) => id !== `task-${taskId}`)
          )
        }, 2000)
      }
    })
  }, [tasksLog, allTasksIds, doneTasksIds])

  return (
    <Accordion
      value={expandedTasks}
      onValueChange={(values) => setExpandedTasks(values)}
      type="multiple"
      collapsible="true"
      className="w-full"
    >
      {
        Object.entries(tasksLog)
          .sort(([, a], [, b]) => a.startedAt - b.startedAt)
          .map(([taskId, taskLog]) => (
            <AccordionItem key={`task-${taskId}`} value={`task-${taskId}`}>
              <AccordionTrigger>
                <div className="flex w-full justify-between items-center mr-4 gap-2">
                  <span className="w-full text-left">{taskLog.message}</span>
                  {taskLog.endedAt !== null ? (
                    <div>
                      <CircleCheck className="text-success-foreground" size="18" />
                    </div>
                  ) : (
                    <div className="animate-spin">
                      <LoaderCircle size="14" />
                    </div>
                  )}
                </div>
              </AccordionTrigger>
              <AccordionContent className="flex flex-col gap-1">
                {
                  Object.entries(taskLog.subTasks)
                    .sort(([, a], [, b]) => a.startedAt - b.startedAt)
                    .map(([key, subTaskLog]: [string, SubTask]) => (
                      <React.Fragment key={key}>
                        <span className="pl-0">· {subTaskLog.startMessage}</span>
                        {subTaskLog.endedAt !== null && (
                          <span className="pl-4 italic text-xs text-muted-foreground">
                            ⤷ {subTaskLog.endMessage}
                          </span>
                        )}
                      </React.Fragment>
                    ))
                }
              </AccordionContent>
            </AccordionItem>
          ))
      }
    </Accordion>
  );
}

interface SideContainerProps {
  phase: string;
  tasksLog: TasksLogCollection;
  totalTasks: number;
}

const SideContainer: React.FC<SideContainerProps> = ({
  phase,
  tasksLog,
  totalTasks,
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let progress = 0;
    const taskContribution = 100 / totalTasks;
    Object.values(tasksLog).forEach(task => {
      if (task.endedAt !== null) {
        progress += taskContribution;
      } else {
        const subTaskContribution = taskContribution / task.totalSubTasks;
        Object.values(task.subTasks).forEach(subTask => {
          if (subTask.endedAt != null) {
            progress += subTaskContribution;
          }
        })
      }
    });

    setProgress(progress);
  }, [tasksLog, totalTasks])
  return (
    <Card className="h-full overflow-hidden flex flex-col">
      <CardHeader className="flex flex-col w-full gap-4 items-center">
        <Badge variant="outline" className="flex gap-1 w-fit">
          <span className="capitalize">{phase}</span>
          {
            progress < 100 ? (
              <div className="animate-spin">
                <LoaderCircle size="14" />
              </div>
            ) : (
              <div>
                <CircleCheck size="14" />
              </div>
            )
          }
        </Badge>
        <Progress value={progress} className="w-full h-2" />
      </CardHeader>
      <Separator />
      <ScrollArea className="flex flex-col h-full gap-2 py-4 overflow-y-auto">
        <CardContent >
          <TasksLog tasksLog={tasksLog} />
        </CardContent>
      </ScrollArea>
    </Card>
  )
}

SideContainer.displayName = "SideContainer"


interface CarouselWizardProps {
  topic: string;
  setTopic: (v: string) => void;
  tone: string;
  setTone: (v: string) => void;
  targetAudience: string;
  setTargetAudience: (v: string) => void;
  onGenerate: () => void;
  onGenerateAsync: () => void;
}


const CarouselWizard: React.FC<CarouselWizardProps> = ({
  topic,
  setTopic,
  tone,
  setTone,
  targetAudience,
  setTargetAudience,
  onGenerate,
  onGenerateAsync,
}) => {
  const [api, setApi] = useState<CarouselApi>()
  const [currentStep, setCurrentStep] = useState(0);
  const [totalSteps, setTotalSteps] = useState(0)
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!api) {
      return
    }

    setTotalSteps(api.scrollSnapList().length)
    setCurrentStep(api.selectedScrollSnap())
    setProgress((api.selectedScrollSnap() + 1) / api.scrollSnapList().length * 100)

    api.on("select", () => {
      setCurrentStep(api.selectedScrollSnap())
      setProgress((api.selectedScrollSnap() + 1) / api.scrollSnapList().length * 100)
    })
  }, [api])

  return (
    <div className="space-y-4">
      <div className="w-full flex flex-col items-center justify-center gap-2">
        <span className="font-medium text-sm">Step {currentStep + 1} of {totalSteps}</span>
        <Progress value={progress} className="w-2/3 h-1" />
      </div>
      <Carousel className="w-full max-w-2xl" setApi={setApi}>

        <CarouselContent>

          <CarouselItem key={0}>
            <div className="p-1">
              <Card>
                <CardHeader>
                  <CardTitle>Topic & Voice Profile</CardTitle>
                  <CardDescription>Define the topic, and the voice and target for this blog post.</CardDescription>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="space-y-2">
                    <Label htmlFor="topic">
                      Topic <span className="italic font-light text-muted-foreground">(Example: The importance of content marketing for B2B SaaS)</span>
                    </Label>
                    <Input
                      id="topic"
                      value={topic}
                      onChange={(e) => setTopic(e.target.value)}
                      placeholder="Enter the topic for this post"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="tone">
                      Tone <span className="italic font-light text-muted-foreground">(Example: Professional)</span>
                    </Label>
                    <Input
                      id="tone"
                      value={tone}
                      onChange={(e) => setTone(e.target.value)}
                      placeholder="Enter custom tone for this post or leave empty to use the workspace default"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="target-audience">
                      Target Audience <span className="italic font-light text-muted-foreground">(Example: SMB founders)</span>
                    </Label>
                    <Input
                      id="target-audience"
                      value={targetAudience}
                      onChange={(e) => setTargetAudience(e.target.value)}
                      placeholder="Enter custom target audience for this post or leave empty to use the workspace default"
                    />
                  </div>
                </CardContent>
              </Card>
            </div>
          </CarouselItem>

          <CarouselItem key={1}>
            <div className="p-1 space-y-4">
              <Card>
                <CardHeader>
                  <CardTitle>Content Strategy</CardTitle>
                  <CardDescription>Define the focus and intent for this blog post.</CardDescription>
                </CardHeader>
                <CardContent className="space-y-4">
                  <div className="space-y-2">
                    <Label htmlFor="user-journey">User Journey Stage</Label>
                    <Select defaultValue="awareness">
                      <SelectTrigger id="user-journey">
                        <SelectValue placeholder="Select user journey stage" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="awareness">Awareness</SelectItem>
                        <SelectItem value="consideration" disabled>Consideration (Not yet supported)</SelectItem>
                        <SelectItem value="decision" disabled>Decision (Not yet supported)</SelectItem>
                        <SelectItem value="retention" disabled>Retention (Not yet supported)</SelectItem>
                      </SelectContent>
                    </Select>
                    <p className="text-sm text-muted-foreground">Currently, only the <span className="font-semibold">Awareness</span> stage is supported.</p>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="search-intent">Search Intent</Label>
                    <Select defaultValue="informative">
                      <SelectTrigger id="search-intent">
                        <SelectValue placeholder="Select search intent" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="informative">Informative</SelectItem>
                        <SelectItem value="navigational" disabled>Navigational (Not yet supported)</SelectItem>
                        <SelectItem value="commercial" disabled>Commercial (Not yet supported)</SelectItem>
                        <SelectItem value="transactional" disabled>Transactional (Not yet supported)</SelectItem>
                      </SelectContent>
                    </Select>
                    <p className="text-sm text-muted-foreground">Currently, only <span className="font-semibold">Informative</span> search intent is supported.</p>
                  </div>
                </CardContent>
              </Card>

              <div className="w-full flex gap-2">
                <Button type="submit" onClick={onGenerate} className="w-full space-x-2" variant="default">
                  <span>Generate & View Progress</span>
                  <ListTodo size={20} />
                </Button>

                <Button type="submit" onClick={onGenerateAsync} className="w-full space-x-2" variant="default">
                  <span>Generate in Background</span>
                  <WandSparkles size={18} />
                </Button>
              </div>

            </div>
          </CarouselItem>

        </CarouselContent>

        {currentStep > 0 && <CarouselPrevious />}
        {currentStep < (totalSteps - 1) && <CarouselNext />}
      </Carousel>
    </div>
  )
}

CarouselWizard.displayName = "CarouselWizard"


interface BasePayload {
  timestamp: number;
}

interface WorkflowStartedPayload extends BasePayload {
  workflow_id: string;
  total_tasks: number;
}

interface WorkflowFinishedPayload extends BasePayload {
  workflow_id: string;

}
interface TaskStartedPayload extends BasePayload {
  task_id: string;
  phase: string;
  message: string;
  total_subtasks: number;
}

interface TaskFinishedPayload extends BasePayload {
  task_id: string;
}

interface SubTaskPayload extends BasePayload {
  task_id: string;
  subtask_id: string;
  message: string;
}

interface CanvasOutlineWritePayload {
  title: string;
  main_body_sections: {
    section_heading: string;
  }[];
  conclusion_heading: string;
}

interface CanvasSectionsWritePayload {
  main_body_sections: {
    section_content: string;
  }[];
}

interface CanvasFramingWritePayload {
  hook: string;
  conclusion_content: string;
}

interface CanvasHeroImagePayload {
  hero_image: string;
}

interface DatabaseSavedPayload extends BasePayload {
  blog_post_id: number;
}

interface WorkflowStartErrorPayload {
  message: string;
}

interface BlogPostLiveProgressProps {
  totalTasks: number;
  phase: string;
  tasksLog: TasksLogCollection;
  blogPost: BlogPost | null;
}

const BlogPostLiveProgress: React.FC<BlogPostLiveProgressProps> = ({
  totalTasks,
  phase,
  tasksLog,
  blogPost,
}) => {
  return (
    <div className="flex gap-4 w-full h-full">
      <Card className="w-full h-full">
        <ScrollArea className="flex flex-col h-full pt-4 pb-4 overflow-y-auto">
          <CardContent>
            {blogPost ? <Canvas blogPost={blogPost} /> : ""}
          </CardContent>
        </ScrollArea>
      </Card>
      <div className="h-full w-full max-w-80">
        <SideContainer
          phase={phase}
          tasksLog={tasksLog}
          totalTasks={totalTasks}
        />
      </div>
    </div>
  )
}

interface ContentBlogPostsGenerateProps {}

const ContentBlogPostsGenerate: React.FC<ContentBlogPostsGenerateProps> = () => {
  const { getToken } = useAuth();
  const socket = useSocket(getToken);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [topic, setTopic] = useState("")
  const [tone, setTone] = useState("")
  const [targetAudience, setTargetAudience] = useState("")

  const currentWorkspace = usePrefsStore((state) => state.currentWorkspace)

  const onGenerateBlogPost = useCallback(() => {
    if (socket) {
      socket.emit("workflow:start", {
        topic, tone, target_audience: targetAudience, workspace_id: currentWorkspace?.id
      })
    }
  }, [socket, topic, tone, targetAudience, currentWorkspace])

  const blogPostAsyncGeneration = useMutation({
    mutationFn: async (blogPostAsyncPayload: components["schemas"]["GenerateBlogPostAsyncRequest"]) => {
      const { data, error, response } = await fetcher.POST("/blog-posts/generate-async", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        body: blogPostAsyncPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get", "blogPosts", currentWorkspace?.id] });
      queryClient.invalidateQueries({ queryKey: ["list", "features-usages"] });

      toast.success("Blog post queued!")
      return navigate("/content/blog-posts/library");
    },
    onError: (error) => {
      toast.error("Cannot generate blog post",
        { description: `${error}` })
    },
  })

  const onGenerateBlogPostAsync = useCallback(() => {
    blogPostAsyncGeneration.mutate({
      topic,
      tone,
      target_audience: targetAudience,
      workspace_id: currentWorkspace?.id as number
    })
  }, [socket, topic, tone, targetAudience, currentWorkspace, blogPostAsyncGeneration])

  const {
    workflowStarted,
    totalTasks,
    phase,
    tasksLog,
    blogPost,
  } = useBlogPostLiveProgress({
    useToastNotifications: true,
    onFinish: (blogPostId: number) => {
      navigate(`/content/blog-posts/edit/${blogPostId}`)
    }
  })

  return (
    <div className="flex flex-col w-full h-full justify-center items-center">
      {!workflowStarted ? (
        <CarouselWizard
          topic={topic}
          setTopic={setTopic}
          tone={tone}
          setTone={setTone}
          targetAudience={targetAudience}
          setTargetAudience={setTargetAudience}
          onGenerate={onGenerateBlogPost}
          onGenerateAsync={onGenerateBlogPostAsync}
        />
      ) : (
        <BlogPostLiveProgress
          totalTasks={totalTasks}
          phase={phase}
          tasksLog={tasksLog}
          blogPost={blogPost}
        />
      )}
    </div>
  )
}

ContentBlogPostsGenerate.displayName = "ContentBlogPostsGenerate";

export { BlogPostLiveProgress, ContentBlogPostsGenerate, useBlogPostLiveProgress };
export type { BlogPost, TasksLogCollection };

