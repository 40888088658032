import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { components } from './openapi';

interface PrefsState {
  currentWorkspace: components["schemas"]["FullWorkspace"] | null;
  setCurrentWorkspace: (workspace: components["schemas"]["FullWorkspace"]) => void;
  resetCurrentWorkspace: () => void;
}

const usePrefsStore = create<PrefsState>()(
  devtools(
    persist(
      (set) => ({
        currentWorkspace: null,
        setCurrentWorkspace: (workspace) => set(() => ({ currentWorkspace: workspace })),
        resetCurrentWorkspace: () => set(() => ({ currentWorkspace: null })),
      }),
      {
        name: 'perfs-storage',
      },
    ),
  ),
)

export { usePrefsStore };
