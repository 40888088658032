import { useAuth, useUser } from '@clerk/clerk-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@repo/ui/components/ui/tooltip";

import { PaginationWidget } from "@repo/ui-kit";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@repo/ui/components/ui/avatar";
import { Badge } from "@repo/ui/components/ui/badge";
import { Button } from "@repo/ui/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@repo/ui/components/ui/table";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Calendar, RotateCw, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { BlogPostStatusCTA, Spinner } from "../organisms/";
import { fetcher, serializeError, useSocket } from "../services/api";
import { components } from '../services/api/openapi';
import { usePrefsStore } from '../store';

interface ContentBlogPostsListProps {}

const ContentBlogPostsList: React.FC<ContentBlogPostsListProps> = () => {
  const { getToken } = useAuth();
  const socket = useSocket(getToken);
  const { isSignedIn } = useUser();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const currentWorkspace = usePrefsStore((state) => state.currentWorkspace)

  useEffect(() => {
    setCurrentPage(parseInt(searchParams.get("page") || "1"))
  }, [searchParams])

  const { data, error, status } = useQuery({
    queryKey: ["get", "blogPosts", currentWorkspace?.id, currentPage],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/blog-posts", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          query: {
            expand: ["author", "content_topic"],
            workspace_id: currentWorkspace?.id,
            order_by: "created_at",
            page_number: currentPage,
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  useEffect(() => {
    if (status !== "pending" && status !== "error") {
      setTotalPages(data.meta.total_pages)
    }
  }, [status, data])

  useEffect(() => {
    if (socket) {
      socket.on("blog-post:status-update", () => {
        // This is not optimal, but it's super easy.
        // Ideally it would be better to just update the status locally and avoid
        // invalidating the query.
        queryClient.invalidateQueries({ queryKey: ["get", "blogPosts", currentWorkspace?.id] });
      })
    }
  }, [socket, currentWorkspace])

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  return (
    <div className="flex flex-col w-full justify-center items-center">
      <div className="flex flex-col gap-8 bg-white rounded-lg border w-full pb-4">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Preview</TableHead>
              <TableHead className="min-w-36">
                <div className="flex flex-col text-center">
                  <span>Search Funnel</span>
                  <span>(Stage + Intent)</span>
                </div>
              </TableHead>
              <TableHead className="text-center min-w-36">Created</TableHead>
              <TableHead className="text-center">Public URL</TableHead>
              <TableHead className="w-[50px]"></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.data.map(bp => {
              const options = { year: "numeric", month: "long", day: "numeric" };
              const formattedCreatedAt = (new Date(bp.created_at)).toLocaleDateString("en-US", options);
              const authorData = data.extra ? data.extra[bp.id] : {};
              const authorFullName = `${authorData.first_name} ${authorData.last_name}`.trim()
              const avatarFallback = (
                (authorData?.first_name ? authorData?.first_name[0] || "" : "") +
                (authorData?.last_name ? authorData?.last_name[0] || "" : "")
              )

              const badgeStyle = bp.content_topic?.content_role.toLowerCase() === "pillar" ?
                "bg-blue-600 text-background" : "bg-cyan-400 text-foreground"

              return (
                <TableRow
                  key={`blogPost-${bp.id}`}
                  className={
                    bp.status === "done" ?
                      "hover:cursor-pointer" :
                      bp.status === "error" ?
                        "" :
                        "hover:cursor-not-allowed bg-muted"
                  }
                  onClick={() => {
                    if (bp.status === "done") {
                      navigate(`/content/blog-posts/edit/${bp.id}`)
                    }
                  }}
                >
                  {
                    bp.status === "done" ? (
                      <TableCell>
                        <div className="flex gap-2 items-center">
                          <img src={bp.hero_image_url} className="w-36 h-full" />
                          <div className="flex flex-col gap-2">
                            <div className="flex flex-col">
                              {
                                bp.content_topic && (
                                  <div className="flex flex-col gap-1 text-sm w-fit">
                                    <Badge className={badgeStyle}>
                                      {bp.content_topic.name}
                                    </Badge>
                                  </div>
                                )
                              }
                              <span className="text-base font-semibold">{bp.title}</span>
                            </div>
                            <span className="text-sm text-muted-foreground font-light">{bp.excerpt}</span>
                          </div>
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell className="italic">
                        <div className="flex flex-col items-center w-full gap-2">
                          <Badge variant={
                            bp.status === "done" ?
                              "success" : bp.status === "error" ?
                                "error" : "info"
                          }
                            className="capitalize">
                            {bp.status}
                          </Badge>

                          <div className="w-full">
                            <span className="font-semibold">Topic</span>: {bp.topic}
                          </div>
                        </div>
                      </TableCell>
                    )
                  }
                  <TableCell>
                    <div className="flex flex-col gap-1 items-center">
                      <Badge variant="outline" className="capitalize">
                        {bp.user_journey_stage}
                      </Badge>
                      <Badge variant="outline" className="capitalize">
                        {bp.search_intent}
                      </Badge>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex flex-col gap-2 text-xs">
                      <div className="flex items-center gap-2">
                        <Avatar>
                          <AvatarImage src={authorData?.image_url} alt="avatar" />
                          {avatarFallback && <AvatarFallback>{avatarFallback}</AvatarFallback>}
                        </Avatar>

                        <span className="font-semibold">{authorFullName}</span>
                      </div>
                      <Badge variant="outline" className="capitalize font-light">
                        <Calendar className="h-4 w-4 mr-1" />
                        {formattedCreatedAt}
                      </Badge>
                    </div>
                  </TableCell>
                  <TableCell>
                    {bp.status === "done" ? (
                      <div
                        onClick={(e) => { e.stopPropagation() }}>
                        <BlogPostStatusCTA
                          blogPostId={bp.id}
                          isPublished={bp.is_published}
                          publishedUrl={bp.published_url}
                        />
                      </div>
                    ) : (
                      <div className="text-center w-full">
                        <span>-</span>
                      </div>
                    )
                    }
                  </TableCell>
                  <TableCell>
                    {bp.status === "done" ? (
                      <DeleteButton blogPostId={bp.id} />
                    ) : bp.status === "error" ? (
                      <div className="flex flex-col">
                        <RegenerateButton blogPostId={bp.id} />
                        <DeleteButton blogPostId={bp.id} />
                      </div>
                    ) : (
                      <Spinner className="h-4 w-4" />
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <PaginationWidget
          pageQueryParam="page"
          currentPage={currentPage}
          maxPage={totalPages}
        />
      </div>
    </div>

  )
}


interface DeleteButtonProps {
  blogPostId: number
}

const DeleteButton = ({
  blogPostId
}: DeleteButtonProps) => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const currentWorkspace = usePrefsStore((state) => state.currentWorkspace)

  const deleteMutation = useMutation({
    mutationFn: async (blogPostId: number) => {
      const { data, error, response } = await fetcher.DELETE("/blog-posts/{blog_post_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            blog_post_id: blogPostId,
          }
        },
      });

      if (error) {
        throw serializeError(error, response.status);
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["get", "blogPosts", currentWorkspace?.id] });
      toast.success("Blog post deleted!",
        { description: data.data.title })
    },
    onError: (error) => {
      toast.error("Cannot delete blog post",
        { description: `${error}` })
    },
  });

  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>

        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8 text-destructive hover:text-destructive hover:bg-destructive/10"
            onClick={(e) => {
              e.stopPropagation();
              if (window.confirm('Are you sure you want to delete this blog post?')) {
                deleteMutation.mutate(blogPostId);
              }
            }}
          >
            <X className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span>Delete</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}


interface RegenerateButtonProps {
  blogPostId: number
}

const RegenerateButton = ({
  blogPostId
}: RegenerateButtonProps) => {
  const { getToken } = useAuth();
  const queryClient = useQueryClient();
  const currentWorkspace = usePrefsStore((state) => state.currentWorkspace)

  const regenerateMutation = useMutation({
    mutationFn: async (blogPostId: number) => {
      const blogPostAsyncPayload: components[
        "schemas"]["RegenerateBlogPostAsyncRequest"] = {
        blog_post_id: blogPostId
      }

      const { data, error, response } = await fetcher.POST("/blog-posts/regenerate-async", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        body: blogPostAsyncPayload
      });

      if (error) {
        throw serializeError(error, response.status);
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get", "blogPosts", currentWorkspace?.id] });
      queryClient.invalidateQueries({ queryKey: ["list", "content-topics", currentWorkspace?.id] });
      queryClient.invalidateQueries({ queryKey: ["list", "features-usages"] });

      toast.success("Blog post enqueued!")
    },
    onError: (error) => {
      toast.error("Cannot regenerate blog post",
        { description: `${error}` })
    },
  });

  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8 text-primary hover:bg-primary/40"
            onClick={(e) => {
              e.stopPropagation();
              regenerateMutation.mutate(blogPostId);
            }}
          >
            <RotateCw className="h-4 w-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span>Retry</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

ContentBlogPostsList.displayName = "ContentBlogPostsList";

export { ContentBlogPostsList };
