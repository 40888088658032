import { useAuth } from '@clerk/clerk-react';
import { Button } from "@repo/ui/components/ui/button";
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOnboardingUpdate } from "../lib/onboarding";
import { BlogPost, BlogPostLiveProgress, TasksLogCollection, useBlogPostLiveProgress } from "./content.blog-posts.generate";

import { ContentTopic, Spinner, WritingStatus } from "../organisms/";
import { fetcher, serializeError, useSocket } from '../services/api';
import { components } from '../services/api/openapi';
import { useOnboardingStore, usePrefsStore } from '../store';

interface OnboardingGenerateBlogPostStartProps {
  topic: components["schemas"]["FullContentTopic"]
}

const OnboardingGenerateBlogPostStart = ({
  topic
}: OnboardingGenerateBlogPostStartProps) => {

  return (
    <div className="flex flex-col gap-24 min-h-screen items-center justify-center">
      <div className="flex flex-col gap-8 items-center">
        <div className="flex flex-col gap-4 items-center text-center max-w-6xl">
          <h1 className="text-6xl font-semibold">It’s happening! 🤩</h1>
          <span className="text-3xl font-regular text-muted-foreground">
            We’re bringing your blog post to life.<br />Watch it build up in real time based on the topic you just picked.
          </span>
        </div>
        <ContentTopic
          topicId={topic.id}
          blogPostId={null}
          contentRole={topic.content_role}
          isBookmarked={topic.is_bookmarked}
          name={topic.name}
          focus={topic.focus}
          targetAudience={topic.target_audience}
          reason={topic.reason}
          status={WritingStatus.IDLE}
          pendingStatusPhase={""}
          childrenTopics={[]}
          enabledControls={[]}
          onTopicClick={null}
        />
      </div>
    </div>
  )
}

interface OnboardingGenerateBlogPostProgressProps {
  totalTasks: number;
  phase: string;
  tasksLog: TasksLogCollection;
  blogPost: BlogPost | null;
  showTiredMessage: boolean;
  showFinishCTA: boolean;
  onFinishCTAClick: () => void;
}

const OnboardingGenerateBlogPostProgress = ({
  totalTasks,
  phase,
  tasksLog,
  blogPost,
  showTiredMessage,
  showFinishCTA,
  onFinishCTAClick,
}: OnboardingGenerateBlogPostProgressProps) => {

  return (
    <div className="flex flex-col gap-4 w-full px-24 min-h-screen mt-8 w-full items-center">
      {
        showTiredMessage && (
          <div className="flex flex-col gap-1 max-w-3xl text-center text-muted-foreground items-center justify-center w-full">
            <div>
              <span className="font-semibold italic">Tired of waiting?</span><span> 😅</span>
            </div>
            <span>
              Don’t worry — inside the app, you’ll be able to generate blog posts in the background.
              This first one?
            </span>
            <span>We wanted to show you the magic under the hood. ✨</span>
          </div>
        )
      }

      {
        showFinishCTA && (
          <div className="flex flex-col gap-1 max-w-3xl text-center items-center justify-center w-full">
            <div>
              <span className="font-semibold italic">Your post is ready!</span><span> 🚀</span>
            </div>
            <span>
              Want to edit, refine, or review sources? Head over to the Content Library — it’s all there.
            </span>

            <Button
              variant="default"
              size="sm"
              onClick={onFinishCTAClick}
            >
              Take me there
            </Button>
          </div>
        )
      }

      <BlogPostLiveProgress
        totalTasks={totalTasks}
        phase={phase}
        tasksLog={tasksLog}
        blogPost={blogPost}
      />
    </div>
  )
}

const OnboardingGenerateBlogPost = () => {
  const { getToken } = useAuth();
  const socket = useSocket(getToken);

  const [contentTopic, setContentTopic] = useState<components["schemas"]["FullContentTopic"] | null>(null);
  const [isTriggered, setIsTriggered] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [showTiredMessage, setShowTiredMessage] = useState(false);
  const [showFinishCTA, setShowFinishCTA] = useState(false);
  const selectedTopicId = useOnboardingStore((state) => state.selectedTopicId)
  const currentWorkspace = usePrefsStore((state) => state.currentWorkspace)
  const onboardingNextStepUpdater = useOnboardingUpdate()
  const navigate = useNavigate();

  useEffect(() => {
    window.setTimeout(() => {
      setIsStarted(true)
    }, 5000)
  }, [])

  useEffect(() => {
    if (socket && contentTopic && isStarted && !isTriggered) {
      setIsTriggered(true)
      window.setTimeout(() => {
        setShowTiredMessage(true)
      }, 10000)
      socket.emit("workflow:start", {
        topic: `${contentTopic.name}. Focus: ${contentTopic.focus}`,
        tone: "",
        target_audience: contentTopic.target_audience,
        workspace_id: currentWorkspace?.id
      })
    }
  }, [socket, contentTopic, isStarted, isTriggered])

  useEffect(() => {
    if (isFinished) {
      setShowTiredMessage(false)
      setShowFinishCTA(true)
    }
  }, [isFinished])

  const { data, error, status } = useQuery({
    queryKey: ["get", "content-topics", selectedTopicId],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/content-topics/{content_topic_id}", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          path: {
            content_topic_id: selectedTopicId as number
          },
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
  })

  useEffect(() => {
    if (status === "success") {
      setContentTopic(data.data)
    }
  }, [status, data])

  const {
    totalTasks,
    phase,
    tasksLog,
    blogPost,
  } = useBlogPostLiveProgress({
    useToastNotifications: false,
    onFinish: () => {
      setIsFinished(true);
    }
  })

  const onFinishCTAClick = useCallback(() => {
    if (onboardingNextStepUpdater) {
      onboardingNextStepUpdater("done")
    }

    return navigate("/content/blog-posts/library")
  }, [onboardingNextStepUpdater])

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  return (
    <>
      {
        isStarted ? (
          <OnboardingGenerateBlogPostProgress
            totalTasks={totalTasks}
            phase={phase}
            tasksLog={tasksLog}
            blogPost={blogPost}
            showTiredMessage={showTiredMessage}
            showFinishCTA={showFinishCTA}
            onFinishCTAClick={onFinishCTAClick}
          />
        ) : (
          <OnboardingGenerateBlogPostStart topic={data.data} />
        )
      }
    </>
  )
}

export { OnboardingGenerateBlogPost };
