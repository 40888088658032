import { cn } from "@repo/ui-core";
import { LoaderCircle } from 'lucide-react';

const Spinner = ({ className }) => {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <LoaderCircle className={cn("animate-spin", className)} />
    </div>
  )
}

Spinner.displayName = "Spinner";

export { Spinner };
