import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@repo/ui/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@repo/ui/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/components/ui/form";
import { Input } from "@repo/ui/components/ui/input";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { cn } from "@repo/ui/lib/utils";
import { cva } from "class-variance-authority";
import { Check, ExternalLink, Link } from "lucide-react";
import { useState } from "react";

const formSchema = z.object({
  website: z.string().url().optional(),
})

type FormType = z.infer<typeof formSchema>;


const blogPostStatusCTAUIVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold",
  {
    variants: {
      variant: {
        draft: "border-gray-200 bg-white",
        published: "border-success bg-success text-success-foreground",
      },
    },
    defaultVariants: {
      variant: "draft",
    },
  }
)

interface BlogPostStatusCTAUIProps {
  isPublished: boolean;
  publishedUrl: string;
  onMarkAsPublished: (website: string) => void;
}

const BlogPostStatusCTAUI = ({
  isPublished,
  publishedUrl,
  onMarkAsPublished,
}: BlogPostStatusCTAUIProps) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
  })

  const variant = isPublished ? "published" : "draft";

  return (
    <>
      <div className={cn(blogPostStatusCTAUIVariants({ variant }))}>
        <span>{isPublished ? "Published" : "Draft"}</span>
        <div className="mx-1 h-3 w-px bg-gray-200"></div>
        {
          isPublished ? (
            publishedUrl ? (
              <a
                href={publishedUrl}
                target="_blank"
                className="inline-flex items-center hover:underline"
              >
                <ExternalLink className="mr-0.5 h-3 w-3" />
                <span>View</span>
              </a>
            ) : (
              <button
                onClick={() => {
                  setDialogOpen(true)
                }}
                className="inline-flex items-center hover:underline"
              >
                <Link className="mr-0.5 h-3 w-3" />
                <span>Set URL</span>
              </button>
            )
          ) : (
            <button
              onClick={() => {
                setDialogOpen(true)
              }}
              className="inline-flex items-center text-blue-600 hover:text-blue-800 hover:underline"
            >
              <Check className="mr-0.5 h-3 w-3" />
              <span>Mark as published</span>
            </button>
          )
        }
      </div>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(({ website }) => {
                onMarkAsPublished(website)
                setDialogOpen(false)
              })}
            >

              <DialogHeader>
                <DialogTitle>Publish Item</DialogTitle>
                <DialogDescription>Enter the URL where the blog post will be published.</DialogDescription>
              </DialogHeader>

              <div className="py-4">
                <FormField
                  control={form.control}
                  name="website"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Website <span className="italic font-light">(optional)</span></FormLabel>
                      <FormControl>
                        <Input placeholder="https://myawesomewebsite.com" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <DialogFooter>
                <Button type="button" variant="outline" onClick={() => setDialogOpen(false)}>
                  Cancel
                </Button>
                <Button type="submit">
                  Publish
                </Button>
              </DialogFooter>
            </form>
          </Form>

        </DialogContent>
      </Dialog>
    </>
  )
}

BlogPostStatusCTAUI.displayName = "BlogPostStatusCTAUI";

export { BlogPostStatusCTAUI };
