import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { components } from './services/api/openapi';

interface PrefsState {
  currentWorkspace: components["schemas"]["FullWorkspace"] | null;
  setCurrentWorkspace: (workspace: components["schemas"]["FullWorkspace"]) => void;
  resetCurrentWorkspace: () => void;
}

const usePrefsStore = create<PrefsState>()(
  devtools(
    persist(
      (set) => ({
        currentWorkspace: null,
        setCurrentWorkspace: (workspace) => set(() => ({ currentWorkspace: workspace })),
        resetCurrentWorkspace: () => set(() => ({ currentWorkspace: null })),
      }),
      {
        name: 'prefs-storage',
      },
    ),
  ),
)

interface OnboardingState {
  selectedTopicId: number | null;
  setSelectedTopicId: (topicId: number) => void;
}

const useOnboardingStore = create<OnboardingState>()(
  devtools(
    persist(
      (set) => ({
        selectedTopicId: null,
        setSelectedTopicId: (topicId) => set(() => ({ selectedTopicId: topicId })),
      }),
      {
        name: 'onboarding-storage',
      },
    ),
  ),
)

export { useOnboardingStore, usePrefsStore };

